'use client';

import { Analytics } from '@vercel/analytics/react';
import Script from 'next/script';
import { ReactNode } from 'react';
import { useConsent } from '../consent/ConsentContext';
const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
export function ThirdPartyIntegrations({
  children
}: {
  children: ReactNode;
}) {
  return <>
      {/* Vercel Analytics - privacy friendly, no consent needed */}
      <Analytics data-sentry-element="Analytics" data-sentry-source-file="ThirdPartyIntegrations.tsx" />

      {/* Google Tag Manager */}
      {GTM_ID && <>
          <Script id="google-tag-manager" strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                'event': 'default_consent',
                'analytics_storage': 'denied',
                'ad_storage': 'denied',
                'wait_for_update': 500
              });
              
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GTM_ID}');
            `}
          </Script>
          <noscript>
            <iframe src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`} height="0" width="0" style={{
          display: 'none',
          visibility: 'hidden'
        }} />
          </noscript>
        </>}



      {process.env.NODE_ENV !== 'development' && <Script id="textmagic_widget_id" src="https://widgets.textmagic.com/messenger-widget-script.js" data-code="fae4be37-83b2-44db-bbde-937dc69108e9" data-wrapper-id="textmagic-widget-wrapper" onLoad={() => {
      const style = document.createElement('style');
      document.head.appendChild(style);

      // Initial check for banner
      const updateStyles = () => {
        const consentBanner = document.getElementById('consent-banner');
        style.textContent = consentBanner ? `
                    #textmagic-widget-wrapper,
                    #textmagic-widget-wrapper iframe,
                    #textmagic-widget-wrapper > div {
                      bottom: 160px !important;
                    }
                    @media (min-width: 641px) {
                      #textmagic-widget-wrapper,
                      #textmagic-widget-wrapper iframe,
                      #textmagic-widget-wrapper > div {
                        bottom: 100px !important;
                      }
                    }
                  ` : '';
      };

      // Check immediately
      updateStyles();

      // Watch for changes
      const observer = new MutationObserver(updateStyles);
      observer.observe(document.body, {
        childList: true,
        subtree: true
      });

      // Cleanup
      window.addEventListener('beforeunload', () => {
        observer.disconnect();
        style.remove();
      });
    }} defer />}

      {children}
    </>;
}