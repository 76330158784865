'use client';

import { faYoutube, faLinkedin, faFacebook, faTiktok, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { CookiePreferencesButton } from './CookiePreferencesButton';
export function Footer() {
  return <footer className="bg-light-backgroundPlainLight px-4 py-12 md:px-8 lg:px-12" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <div className="mx-auto max-w-7xl">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-4">
          <div>
            <h3 className="mb-1 text-xl font-semibold text-light-textPurplePlainPrimary">
              Buy an EV
            </h3>
            <ul className="space-y-1">
              <li>
                <Link href="/vehicles" data-action="footer-vehicles" className="text-light-textPlainSecondary hover:text-light-textPlainTertiary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                  EVs for sale
                </Link>
              </li>
              <li>
                <Link href="/learning" data-action="footer-learning" className="text-light-textPlainSecondary hover:text-light-textPlainTertiary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                  Learn about EVs
                </Link>
              </li>
              <li>
                <Link href="/chargers" data-action="footer-chargers" className="text-light-textPlainSecondary hover:text-light-textPlainTertiary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                  Charging
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="mb-1 text-xl font-semibold text-light-textPurplePlainPrimary">
              Sell or trade
            </h3>
            <ul className="space-y-1">
              <li>
                <Link href="/sell" data-action="footer-sell" className="text-light-textPlainSecondary hover:text-light-textPlainTertiary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                  How it works
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="mb-1 text-xl font-semibold text-light-textPurplePlainPrimary">
              Financing
            </h3>
            <ul className="space-y-1">
              <li>
                <Link href="/prequalify" data-action="footer-prequalify" className="text-light-textPlainSecondary hover:text-light-textPlainTertiary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                  Get pre-qualified
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="mb-1 text-xl font-semibold text-light-textPurplePlainPrimary">
              Contact us
            </h3>
            <ul className="space-y-1">
              <li>
                <Link href="tel:8043905910" data-action="footer-phone" className="text-light-textPlainSecondary hover:text-light-textPlainTertiary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                  Call us at (804) 390-5910
                </Link>
              </li>
              <li>
                <Link href="mailto:hello@recharged.com" data-action="footer-email" className="text-light-textPlainSecondary hover:text-light-textPlainTertiary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                  Email us at hello@recharged.com
                </Link>
              </li>
              <li>
                <Link href="https://maps.google.com/?q=8410+W+Broad+Street+Richmond+VA+23294" data-action="footer-experience-center" className="text-light-textPlainSecondary hover:text-light-textPlainTertiary whitespace-pre-line" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                  Visit our Experience Center
                  {'\n'}8410 W Broad Street,
                  {'\n'}Richmond, VA 23294
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-12 flex flex-col items-start justify-between border-t border-light-strokeNormal pt-8 md:flex-row md:items-center">
          <div className="mb-4 md:mb-0">
            <div className="mb-2 flex items-center space-x-2">
              <div className="h-6 w-6" style={{
              backgroundImage: 'url(/logo-symbol.svg)',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain'
            }} />
              <div className="h-8 w-[92px]" style={{
              backgroundImage: 'url(/logo-wordmark.svg)',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain'
            }} />
            </div>
            <p className="mt-2 text-sm text-light-textPlainTertiary">
              © 2025 Recharged. All Rights Reserved.
            </p>

            <div className="my-1 mt-4 flex flex-wrap items-center gap-1 text-sm text-light-textPlainTertiary">
              <Link href="/returns" data-action="footer-returns" className="hover:text-light-textPlainSecondary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                7-Day Return Policy
              </Link>
              <span>·</span>
              <Link href="/privacy" data-action="footer-privacy" className="hover:text-light-textPlainSecondary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                Privacy Policy
              </Link>
              <span>·</span>
              <Link href="/do-not-sell" data-action="footer-do-not-sell" className="hover:text-light-textPlainSecondary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                Do Not Sell or Share My Information
              </Link>
              <span>·</span>
              <CookiePreferencesButton data-sentry-element="CookiePreferencesButton" data-sentry-source-file="Footer.tsx" />
            </div>
          </div>

          <div className="flex space-x-4 text-light-textPlainTertiary">
            <a href="https://www.tiktok.com/@recharged_evs" data-action="footer-social-tiktok" target="_blank" rel="noopener noreferrer" className="hover:text-light-textPlainSecondary">
              <FontAwesomeIcon icon={faTiktok} className="text-xl" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="Footer.tsx" />
              <span className="sr-only">TikTok</span>
            </a>
            <a href="https://www.youtube.com/@RechargedEVs" data-action="footer-social-youtube" target="_blank" rel="noopener noreferrer" className="hover:text-light-textPlainSecondary">
              <FontAwesomeIcon icon={faYoutube} className="text-xl" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="Footer.tsx" />
              <span className="sr-only">YouTube</span>
            </a>
            <a href="https://www.instagram.com/recharged_evs" data-action="footer-social-instagram" target="_blank" rel="noopener noreferrer" className="hover:text-light-textPlainSecondary">
              <FontAwesomeIcon icon={faInstagram} className="text-xl" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="Footer.tsx" />
              <span className="sr-only">Instagram</span>
            </a>
            <a href="https://www.linkedin.com/company/recharged-evs/" data-action="footer-social-linkedin" target="_blank" rel="noopener noreferrer" className="hover:text-light-textPlainSecondary">
              <FontAwesomeIcon icon={faLinkedin} className="text-xl" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="Footer.tsx" />
              <span className="sr-only">LinkedIn</span>
            </a>
            <a href="https://www.facebook.com/Recharged.EVs" data-action="footer-social-facebook" target="_blank" rel="noopener noreferrer" className="hover:text-light-textPlainSecondary">
              <FontAwesomeIcon icon={faFacebook} className="text-xl" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="Footer.tsx" />
              <span className="sr-only">Facebook</span>
            </a>
          </div>
        </div>
      </div>
    </footer>;
}