'use client';

import { faClose, faBars, faChevronDown } from '@awesome.me/kit-e1dfe1051a/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { useState, Suspense, useRef, useEffect } from 'react';
function NavigationContent({
  isMobile = false,
  onNavigate
}: {
  isMobile?: boolean;
  onNavigate?: () => void;
}) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const params = new URLSearchParams(searchParams ?? {});
  const vehiclesHref = `/vehicles${params.size > 0 ? `?${params.toString()}` : ''}`;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  const mainLinks = [{
    href: vehiclesHref,
    label: 'Shop EVs'
  }, {
    href: '/sell',
    label: 'Sell or trade'
  }, {
    href: '/chargers',
    label: 'Charging'
  }];
  const dropdownLinks = [{
    href: '/prequalify',
    label: 'Get pre-qualified'
  }, {
    href: '/learning',
    label: 'Learn about EVs'
  }, {
    href: '/contact',
    label: 'Contact us'
  }];
  const baseClasses = isMobile ? 'flex flex-col space-y-6 text-lg' : 'hidden items-center space-x-8 md:flex';
  const linkClasses = `font-bold transition-colors hover:text-light-textPurplePlainTertiary`;
  const activeLinkClasses = 'text-light-textPurplePlainPrimary';
  const inactiveLinkClasses = 'text-light-textPurplePlainSecondary';
  return <nav className={baseClasses} data-sentry-component="NavigationContent" data-sentry-source-file="Header.tsx">
      {mainLinks.map(({
      href,
      label
    }, index) => <Link key={href} href={href} onClick={onNavigate} className={`${linkClasses} ${pathname === href.split('?')[0] ? activeLinkClasses : inactiveLinkClasses} ${isMobile && index !== 0 ? 'border-t border-light-strokeSoft pt-6' : ''}`} replace={href === vehiclesHref}>
          {label}
        </Link>)}

      {isMobile ?
    // Show all links in mobile menu
    dropdownLinks.map(({
      href,
      label
    }, index) => <Link key={href} href={href} onClick={onNavigate} className={`${linkClasses} ${pathname === href ? activeLinkClasses : inactiveLinkClasses} border-t border-light-strokeSoft pt-6`}>
            {label}
          </Link>) :
    // Updated dropdown menu
    <div className="relative" ref={dropdownRef}>
          <button className={`${linkClasses} ${inactiveLinkClasses} flex items-center gap-1`} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            More{' '}
            <FontAwesomeIcon icon={faChevronDown} size="xs" className={`transform transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`} />
          </button>

          {isDropdownOpen && <div className="absolute right-0 mt-2 w-48 rounded-2xl bg-white py-2 shadow-xl ring-1 ring-black/5">
              {dropdownLinks.map(({
          href,
          label
        }, index) => <Link key={href} href={href} onClick={() => {
          setIsDropdownOpen(false);
          onNavigate?.();
        }} className={`block px-4 py-3 ${linkClasses} ${pathname === href ? activeLinkClasses : inactiveLinkClasses} ${index !== 0 ? 'border-t border-light-strokeSoft' : ''}`}>
                  {label}
                </Link>)}
            </div>}
        </div>}
    </nav>;
}
function Navigation(props: {
  isMobile?: boolean;
  onNavigate?: () => void;
}) {
  return <Suspense fallback={<nav className={props.isMobile ? 'flex flex-col space-y-6' : 'hidden md:flex items-center space-x-8'}>
          <div className="h-6" />
        </nav>} data-sentry-element="Suspense" data-sentry-component="Navigation" data-sentry-source-file="Header.tsx">
      <NavigationContent {...props} data-sentry-element="NavigationContent" data-sentry-source-file="Header.tsx" />
    </Suspense>;
}
function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const Logo = () => <Link href="/" className="flex items-center" data-sentry-element="Link" data-sentry-component="Logo" data-sentry-source-file="Header.tsx">
      <div className="relative h-[24px] w-[150px]">
        <Image src="/logo.svg" alt="Recharged" fill sizes="150px" priority className="object-contain" data-sentry-element="Image" data-sentry-source-file="Header.tsx" />
      </div>
    </Link>;
  return <header className="relative z-50 bg-light-backgroundPlainPure" data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      {/* Desktop Header */}
      <div className="container mx-auto px-4">
        <div className="flex h-16 items-center justify-between">
          <Logo data-sentry-element="Logo" data-sentry-source-file="Header.tsx" />
          <Navigation data-sentry-element="Navigation" data-sentry-source-file="Header.tsx" />
          <button className="md:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)} aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}>
            <div className="flex items-center justify-center gap-1 rounded-2xl font-semibold bg-light-backgroundPlainLight px-3 py-2 text-sm">
              {isMenuOpen ? 'Close' : 'Menu'}{' '}
              <FontAwesomeIcon icon={isMenuOpen ? faClose : faBars} className="text-light-textPurplePlainSecondary" size="xs" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="Header.tsx" />
            </div>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && <>
          {/* Overlay */}
          <div className="fixed inset-0 -z-10 bg-black/20 md:hidden" onClick={() => setIsMenuOpen(false)} />

          {/* Menu Content */}
          <div className="absolute inset-x-0 top-0 z-50 md:hidden">
            <div className="mx-0.5 rounded-b-2xl bg-light-backgroundPlainPure shadow-lg">
              <div className="container mx-auto px-4">
                {/* Mobile Header */}
                <div className="flex h-16 items-center justify-between">
                  <Logo />
                  <button className="" onClick={() => setIsMenuOpen(false)} aria-label="Close menu">
                    <div className="flex items-center font-semibold justify-center gap-1 rounded-xl bg-light-backgroundPlainLight px-3 py-2 text-sm">
                      Close{' '}
                      <FontAwesomeIcon icon={faClose} className="text-light-textPurplePlainSecondary" size="xs" />
                    </div>
                  </button>
                </div>

                {/* Mobile Navigation */}
                <div className="px-4 py-8">
                  <Navigation isMobile onNavigate={() => setIsMenuOpen(false)} />
                </div>
              </div>
            </div>
          </div>
        </>}
    </header>;
}
export { Header };