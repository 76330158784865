import { Vehicle } from '~/server/db/schema';

export function formatUrlSegment(segment: string): string {
  return encodeURIComponent(
    segment
      .toLowerCase()
      .replace(/[\s-]+/g, '-')
      .replace(/[^a-z0-9.-]/g, ''),
  );
}

export function decodeUrlSegment(segment: string): string {
  const decoded = decodeURIComponent(segment);

  return decoded
    .toLowerCase()
    .replace(/(?<!\.)-(?!\.)/g, ' ')
    .trim();
}

export function getVehicleUrl(
  make: string,
  model: string,
  vin: string,
): string {
  return `/vehicles/${formatUrlSegment(make)}/${formatUrlSegment(model)}/${vin}`;
}

export function getFullUrl(vehicle: Vehicle): string {
  const vehicleUrl = getVehicleUrl(vehicle.make, vehicle.model, vehicle.vin);
  return `${process.env.NEXT_PUBLIC_APP_URL}${vehicleUrl}`;
}
