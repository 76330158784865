'use client';

import React, { useRef, useEffect, useState } from 'react';
import { useConsent } from '~/app/_components/providers/consent/ConsentContext';
interface AutoTrackProps {
  children: React.ReactNode;
  componentName?: string;
  data?: Record<string, unknown>;
}
export function AutoTrack({
  children,
  componentName,
  data
}: AutoTrackProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    consents,
    isDoNotSell
  } = useConsent();
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    if (!consents.analytics || isDoNotSell || !containerRef.current) return;
    const container = containerRef.current;
    const handleInteraction = (event: Event) => {
      const target = event.target as HTMLElement;
      if (target.classList.contains('ph-no-capture')) return;
      const properties = {
        componentName,
        elementType: target.tagName.toLowerCase(),
        elementText: target.textContent?.trim(),
        elementId: target.id,
        elementClasses: Array.from(target.classList).join(' '),
        formId: target.closest('form')?.id,
        formAction: target.closest('form')?.action,
        ...data,
        path: window.location.pathname,
        url: window.location.href,
        timestamp: new Date().toISOString()
      };
      switch (event.type) {
        case 'click':
          if (target.matches('button, a, [role="button"]')) {
            window.dataLayer?.push({
              event: 'element_clicked',
              ...properties
            });
          }
          break;
        case 'submit':
          if (target.matches('form')) {
            window.dataLayer?.push({
              event: 'form_submitted',
              ...properties
            });
          }
          break;
        case 'change':
          if (target.matches('input, select, textarea')) {
            window.dataLayer?.push({
              event: 'input_changed',
              ...properties,
              inputType: (target as HTMLInputElement).type,
              inputName: (target as HTMLInputElement).name
            });
          }
          break;
      }
    };
    container.addEventListener('click', handleInteraction);
    container.addEventListener('submit', handleInteraction);
    container.addEventListener('change', handleInteraction);
    return () => {
      container.removeEventListener('click', handleInteraction);
      container.removeEventListener('submit', handleInteraction);
      container.removeEventListener('change', handleInteraction);
    };
  }, [componentName, data, consents.analytics, isDoNotSell]);
  return <div ref={containerRef} data-recording-enabled={mounted ? consents.analytics : undefined} data-sentry-component="AutoTrack" data-sentry-source-file="AutoTrack.tsx">
      {children}
    </div>;
}