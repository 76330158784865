'use client';

import * as Sentry from '@sentry/nextjs';
import { useEffect, useState } from 'react';
export function ChunkErrorHandler() {
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    const handleChunkError = (event: ErrorEvent) => {
      const isChunkLoadError = event.message?.includes('Loading chunk') || event.message?.includes('Failed to fetch dynamically imported module') || event.error?.toString().includes('ChunkLoadError');
      if (isChunkLoadError) {
        setHasError(true);
        Sentry.captureException(event.error || event, {
          tags: {
            error_type: 'chunk_load_error',
            chunk_id: event.message?.match(/chunk \d+/)?.[0] || 'unknown'
          },
          extra: {
            message: event.message,
            filename: event.filename,
            lineno: event.lineno,
            colno: event.colno,
            url: window.location.href
          }
        });
        try {
          if (typeof window !== 'undefined' && window.localStorage) {
            window.localStorage.clear();
            window.sessionStorage.clear();
          }
        } catch (e) {
          console.error('Failed to clear storage:', e);
        }
        setTimeout(() => {
          const params = new URLSearchParams(window.location.search);
          const reloadAttempts = parseInt(params.get('reloadAttempts') || '0');
          if (reloadAttempts < 2) {
            params.set('reloadAttempts', (reloadAttempts + 1).toString());
            params.set('t', Date.now().toString());
            window.location.search = params.toString();
          } else {
            window.location.href = '/';
          }
        }, 2000);
      }
    };
    window.addEventListener('error', handleChunkError);
    return () => window.removeEventListener('error', handleChunkError);
  }, []);
  if (hasError) {
    return <div className="fixed inset-0 z-50 flex items-center justify-center bg-backgroundPlainPure/80">
        <div className="rounded-lg bg-backgroundPlainPure p-6 shadow-lg">
          <p className="text-textPlainPrimary">
            Reloading page due to a loading error...
          </p>
        </div>
      </div>;
  }
  return null;
}