'use client';

import { useEffect } from 'react';
import { VehicleCard } from '~/app/_components/vehicles/VehicleCard';
import { type Vehicle } from '~/server/db/schema';
import { api } from '~/trpc/react';
import { Carousel } from '../Carousel';
interface VehicleCarouselProps {
  title: string;
  limit?: number;
}
export function VehicleCarousel({
  title,
  limit = 12
}: VehicleCarouselProps) {
  const {
    data: vehicles,
    isLoading
  } = api.vehicle.getNewestVehicles.useQuery({
    limit
  }, {
    staleTime: 1000 * 60 * 60,
    // 1 hour
    refetchOnMount: false,
    // Prevent unnecessary refetches
    refetchOnWindowFocus: false
  });

  // Prefetch first 4 images
  useEffect(() => {
    vehicles?.slice(0, 4).forEach(vehicle => {
      const img = new window.Image();
      img.src = vehicle.images?.[0]?.url ?? '/placeholder.svg';
    });
  }, [vehicles]);
  return <div className="mx-auto" data-sentry-component="VehicleCarousel" data-sentry-source-file="VehicleCarousel.tsx">
      <h2 className="mb-4 md:mb-6 text-2xl md:text-4xl font-bold">{title}</h2>
      {isLoading ? <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
          {Array.from({
        length: 4
      }).map((_, i) => <div key={i} className="animate-pulse rounded-xl border border-light-strokeNormal">
              <div className="aspect-[4/3] w-full rounded-t-xl bg-light-backgroundPlainHeavy" />
              <div className="p-4 space-y-4">
                <div className="space-y-2">
                  <div className="h-7 w-3/4 rounded bg-light-backgroundPlainHeavy" />
                  <div className="flex gap-1">
                    <div className="h-5 w-20 rounded bg-light-backgroundPlainHeavy" />
                    <div className="h-5 w-4 rounded bg-light-backgroundPlainHeavy" />
                    <div className="h-5 w-16 rounded bg-light-backgroundPlainHeavy" />
                  </div>
                </div>
                <div className="h-6 w-24 rounded bg-light-backgroundPlainHeavy" />
                <div className="h-8 w-32 rounded bg-light-backgroundPlainHeavy" />
              </div>
            </div>)}
        </div> : <Carousel<Vehicle> items={vehicles ?? []} itemsPerBreakpoint={{
      base: 1,
      sm: 2,
      md: 3,
      xl: 4
    }} renderItem={(vehicle: Vehicle) => <VehicleCard key={vehicle.id} vehicle={vehicle} showMonthlyPayment={false} includeTaxCredit={false} disableCarousel={true} isPriority={(vehicles ?? []).indexOf(vehicle) < 4} />} />}
    </div>;
}