export const colorTokens = {
  // /* #ffffff */ gray000: '#ffffff',
  // /* #fbfaf9 */ gray100: '#fbfaf9',
  // /* #f1efee */ gray200: '#f1efee',
  // /* #e9e7e4 */ gray300: '#e9e7e4',
  // /* #c4bfb9 */ gray400: '#c4bfb9',
  ///* #7f7a75 */ gray500: '#7f7a75',
  ///* #595652 */ gray700: '#595652',
  // /* #343231 */ gray750: '#343231',
  // /* #242322 */ gray800: '#242322',
  // /* #151413 */ gray850: '#151413',
  // /* #050504 */ gray900: '#050504',
  // /* #ff4e4e */ red500: '#ff4e4e',
  // /* #e50000 */ red700: '#e50000',
  // /* #2793f3 */ blue500: '#2793f3',
  // /* #f5fefa */ greenBrand100: '#f5fefa',
  // /* #cffcea */ greenBrand200: '#cffcea',
  // /* #9ff8d4 */ greenBrand300: '#9ff8d4',
  // /* #6ff5bf */ greenBrand400: '#6ff5bf',
  // /* #10ef95 */ greenBrand500: '#10ef95',
  // /* #0ed786 */ greenBrand600: '#0ed786',
  // /* #0dc87d */ greenBrand650: '#0dc87d',
  // /* #0cb571 */ greenBrand700: '#0cb571',
  // /* #0aa265 */ greenBrand750: '#0aa265',
  // /* #042f08 */ greenBrand900: '#042f08',
  // /* #064b2f */ greenBrand800: '#064b2f',
  // /* #95aba2 */ greenMuted300: '#95aba2',
  // /* #4c6d51 */ greenMuted600: '#4c6d51',
  // /* #faf9ff */ purpleBrand100: '#faf9ff',
  // /* #eeeaff */ purpleBrand200: '#eeeaff',
  // /* #dcd6ff */ purpleBrand300: '#dcd6ff',
  // /* #b2a3ff */ purpleBrand400: '#b2a3ff',
  // /* #8770ff */ purpleBrand500: '#8770ff',
  // /* #5e3efb */ purpleBrand600: '#5e3efb',
  // /* #4322ec */ purpleBrand700: '#4322ec',
  // /* #200f75 */ purpleBrand800: '#200f75',
  // /* #130948 */ purpleBrand850: '#130948',
  // /* #0b042e */ purpleBrand900: '#0b042e',
  // /* #534e6d */ purpleMuted600: '#534e6d',
  // /* #615b7f */ purpleMuted500: '#615b7f',
  // /* #837f97 */ purpleMuted300: '#837f97',
  // /* #ffe47a */ yellow300: '#ffe47a',
  // /* #ffdc52 */ yellow400: '#ffdc52',
  // /* #ffc640 */ yellow500: '#ffc640',
} as const;

export const colorAssignments = {
  light: {
    backgroundPlainPure: '#ffffff',
    textPlainPrimary: '#050504',
    strokeNormal: '#e9e7e4',
    graphicsGreenIcons: '#0ed786',
    textPlainInactive: '#e9e7e4',
    textPlainSecondary: '#595652',
    textPlainTertiary: '#7f7a75',
    backgroundPlainLight: '#fbfaf9',
    systemForegroundStark: '#000000',
    systemBackgroundChrome: '#f9f9f9ef',
    systemForegroundChrome: '#ffffff33',
    systemForegroundHighlight: '#2e7cf6',
    systemForegroundDisabled: '#cecece',
    systemBackgroundBlack: '#000000',
    textPlainQuaternary: '#c4bfb9',
    textPurplePlainPrimary: '#0b042e',
    strokeSoft: '#f1efee',
    backgroundPurpleLight: '#faf9ff',
    graphicsGreenBackgrounds: '#9ff8d4',
    textGreenBrandPrimary: '#0aa265',
    textRedError: '#e50000',
    strokeActive: '#4322ec',
    textPlainInverse: '#ffffff',
    textPurpleBrandPrimary: '#4322ec',
    backgroundPlainHeavy: '#e9e7e4',
    backgroundPlainMedium: '#f1efee',
    graphicsPurpleButtons: '#5e3efb',
    backgroundPlainBehindSheet: '#595652',
    graphicsPurpleBackgrounds: '#dcd6ff',
    textPurplePlainTertiary: '#837f97',
    backgroundGreenLight: '#f5fefa',
    graphicsPurpleIcons: '#8770ff',
    textPurplePlainSecondary: '#534e6d',
    graphicsGreenButtons: '#10ef95',
    textGreenPlainSecondary: '#4c6d51',
    textGreenPlainTertiary: '#95aba2',
    textGreenPlainPrimary: '#042f08',
    textGreenBrandSecondary: '#064b2f',
    textPurpleBrandSecondary: '#200f75',
    graphicsGreenLogo: '#0dc87d',
    backgroundPurpleHeavy: '#130948',
    backgroundGreenHeavy: '#cffcea',
    textPurpleBrandOnGreen: '#0b042e',
    backgroundPlainOverContent: '#ffffffcc',
    backgroundPlainBehindOverlay: '#0000004c',
  },
  // dark: {
  //   backgroundPlainPure: '#050504',
  //   textPlainPrimary: '#ffffff',
  //   strokeNormal: '#595652',
  //   graphicsGreenIcons: '#10ef95',
  //   textPlainInactive: '#7f7a75',
  //   textPlainSecondary: '#c4bfb9',
  //   textPlainTertiary: '#7f7a75',
  //   backgroundPlainLight: '#151413',
  //   systemForegroundStark: '#ffffff',
  //   systemBackgroundChrome: '#3d3d3def',
  //   systemForegroundChrome: '#ffffff33',
  //   systemForegroundHighlight: '#5794f7',
  //   systemForegroundDisabled: '#707070',
  //   systemBackgroundBlack: '#000000',
  //   textPlainQuaternary: '#595652',
  //   textPurplePlainPrimary: '#faf9ff',
  //   strokeSoft: '#343231',
  //   backgroundPurpleLight: '#0b042e',
  //   graphicsGreenBackgrounds: '#10ef95',
  //   textGreenBrandPrimary: '#0cb571',
  //   textRedError: '#ff4e4e',
  //   strokeActive: '#8770ff',
  //   textPlainInverse: '#050504',
  //   textPurpleBrandPrimary: '#eeeaff',
  //   backgroundPlainHeavy: '#7f7a75',
  //   backgroundPlainMedium: '#343231',
  //   graphicsPurpleButtons: '#b2a3ff',
  //   backgroundPlainBehindSheet: '#595652',
  //   graphicsPurpleBackgrounds: '#5e3efb',
  //   textPurplePlainTertiary: '#615b7f',
  //   backgroundGreenLight: '#042f08',
  //   graphicsPurpleIcons: '#b2a3ff',
  //   textPurplePlainSecondary: '#b2a3ff',
  //   graphicsGreenButtons: '#10ef95',
  //   textGreenPlainSecondary: '#0aa265',
  //   textGreenPlainTertiary: '#4c6d51',
  //   textGreenPlainPrimary: '#0cb571',
  //   textGreenBrandSecondary: '#0ed786',
  //   textPurpleBrandSecondary: '#8770ff',
  //   graphicsGreenLogo: '#10ef95',
  //   backgroundPurpleHeavy: '#eeeaff',
  //   backgroundGreenHeavy: '#064b2f',
  //   textPurpleBrandOnGreen: '#0b042e',
  //   backgroundPlainOverContent: '#000000cc',
  //   backgroundPlainBehindOverlay: '#000000bf',
  // },
} as const;
