import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface PriceModifiersState {
  showTaxCredit: boolean;
  taxCreditDismissed: boolean;
  taxCreditDismissedAt: number | null;
  taxCreditAmount: number;
   
  setShowTaxCredit: (show: boolean) => void;
   
  setTaxCreditDismissed: (dismissed: boolean) => void;
   
  setTaxCreditAmount: (amount: number) => void;
  reset: () => void;
}

const defaultValues = {
  showTaxCredit: true,
  taxCreditDismissed: false,
  taxCreditDismissedAt: null,
  taxCreditAmount: 4500,
};

const storage = typeof window !== 'undefined'
  ? createJSONStorage(() => window.localStorage)
  : createJSONStorage(() => ({
      getItem: () => null,
      setItem: () => {},
      removeItem: () => {},
    }));

export const usePriceModifiers = create<PriceModifiersState>()(
  persist(
    (set) => ({
      ...defaultValues,
      setShowTaxCredit: (show) => set({ showTaxCredit: show }),
      setTaxCreditDismissed: (dismissed) =>
        set({
          taxCreditDismissed: dismissed,
          taxCreditDismissedAt: dismissed ? Date.now() : null,
        }),
      setTaxCreditAmount: (amount) => set({ taxCreditAmount: amount }),
      reset: () => set(defaultValues),
    }),
    {
      name: 'price-modifiers',
      storage,
    },
  ),
);
