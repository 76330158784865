'use client';

import { faCalculatorSimple } from '@awesome.me/kit-e1dfe1051a/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getBaseInterestRateByCredit } from '~/helpers/loan';
import { getDisplayPrice } from '~/helpers/vehicle';
import { type Vehicle } from '~/server/db/schema';
import { useLoanTerms } from '~/store/loan-terms';
import { usePriceModifiers } from '~/store/price-modifiers';
interface PriceDisplayProps {
  vehicle: Vehicle;
  showMonthlyPayment?: boolean;
  includeTaxCredit?: boolean;
  variant?: 'card' | 'detail';
}
export function PriceDisplay({
  vehicle,
  showMonthlyPayment: forceShowMonthlyPayment,
  includeTaxCredit,
  variant = 'card'
}: PriceDisplayProps) {
  const {
    showMonthlyPayment,
    downPayment,
    creditScore,
    loanTermMonths
  } = useLoanTerms();
  const {
    showTaxCredit
  } = usePriceModifiers();
  const displayMonthlyPayment = forceShowMonthlyPayment ?? showMonthlyPayment;
  const shouldShowTaxCredit = includeTaxCredit ?? showTaxCredit;
  const monthlyPayment = displayMonthlyPayment;
  const interestRate = getBaseInterestRateByCredit(creditScore);
  const displayPrice = (withTaxCredit: boolean, useMonthlyPayments: boolean) => {
    const isEligibleForCredit = vehicle.fedTaxCredit && shouldShowTaxCredit;
    return getDisplayPrice(vehicle, {
      includeTaxCredit: withTaxCredit && isEligibleForCredit,
      useMonthlyPayments,
      downPayment,
      creditScore,
      loanTermMonths
    });
  };
  const showTaxCreditLabel = vehicle.fedTaxCredit && shouldShowTaxCredit;
  return <div className={variant === 'card' ? 'flex flex-col' : 'grid grid-cols-1 gap-2 mb-4 md:grid-cols-2 max-w-[50rem]'} data-sentry-component="PriceDisplay" data-sentry-source-file="PriceDisplay.tsx">
      {/* Cash Price */}
      <div className={`
        py-3 
        ${variant === 'detail' ? 'px-4 rounded-2xl' : 'border-t bg-transparent rounded-none'}
        ${!monthlyPayment && variant === 'detail' ? 'md:col-span-2' : ''}
      `}>
        <div className="flex flex-col">
          <div className="h-6 overflow-hidden">
            {showTaxCreditLabel && <span className="text-light-textPlainQuaternary text-bold line-through" data-testid={`previous-price-${vehicle.id}`}>
                {displayPrice(false, false)}
              </span>}
          </div>
          <div className="flex items-baseline gap-1">
            <span className={`text-2xl font-bold ${variant === 'card' ? 'text-xl text-light-textPurplePlainPrimary' : ''}`} data-testid={`price-display-${vehicle.id}`}>
              {displayPrice(true, false)}
            </span>
            {showTaxCreditLabel && <span className="text-light-textPlainTertiary text-bold text-md">
                after tax credit
              </span>}
          </div>
        </div>
      </div>

      {/* Monthly Payment */}
      {monthlyPayment && <div className={`${variant === 'detail' ? 'bg-light-backgroundPlainLight rounded-2xl px-4 py-3' : 'border-t pt-3'}`}>
          <div className="flex flex-col">
            <div className="h-6 overflow-hidden">
              {showTaxCreditLabel && <span className="text-light-textPlainQuaternary text-bold line-through" data-testid={`previous-monthly-payment-${vehicle.id}`}>
                  {displayPrice(false, true)}
                </span>}
            </div>
            <div className="flex items-baseline gap-1">
              <span className={`text-2xl font-bold ${variant === 'card' ? 'text-xl text-light-textPurplePlainPrimary' : ''}`} data-testid={`monthly-payment-${vehicle.id}`}>
                {displayPrice(true, true)}
              </span>
              {showTaxCreditLabel && <span className="text-light-textPlainTertiary text-bold text-md">
                  after tax credit
                </span>}
            </div>
            <span className="text-light-textPlainSecondary text-xs tracking-tighter">
              <FontAwesomeIcon icon={faCalculatorSimple} className="inline-block" size="xs" /> Sample terms • $
              {downPayment.toLocaleString()} down • {interestRate.toFixed(2)}%
              APR • {loanTermMonths} mo
            </span>
          </div>
        </div>}
    </div>;
}