import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import { api } from '~/lib/axios';
import { logger } from '~/utils/logger';

export type LocationData = {
  city: string;
  state: string;
  country: string;
  latitude: number;
  longitude: number;
  zip: string;
  ip: string;
  error?: string;
};

export interface LocationState {
  location: LocationData | null;
  isLoading: boolean;
  setLocation: (location: LocationData) => void;
  reset: () => void;
  getLocationIfNeeded: () => void;
}

const defaultLocation: LocationData = {
  city: '',
  state: '',
  country: '',
  latitude: 0,
  longitude: 0,
  zip: '',
  ip: '',
};

const storage = typeof window !== 'undefined' 
  ? createJSONStorage(() => window.localStorage)
  : createJSONStorage(() => ({
      getItem: () => null,
      setItem: () => {},
      removeItem: () => {},
    }))

export const useLocation = create<LocationState>()(
  persist(
    (set, get) => ({
      location: null,
      isLoading: false,
      setLocation: (location) => set({ location, isLoading: false }),
      reset: () => set({ location: null, isLoading: false }),
      getLocationIfNeeded: () => {
        const state = get();
        if (!state.location && !state.isLoading) {
          getUserLocation().catch((error) => {
            logger.error('Location fetch error', { error });
            get().reset();
          });
        }
      },
    }),
    {
      name: 'user-location',
      storage,
    },
  ),
);

export async function getUserLocation(): Promise<LocationData> {
  useLocation.setState({ isLoading: true });
  
  try {
    const data: LocationData = await api.get('/api/location');
    useLocation.getState().setLocation(data);
    return data;
  } catch (error) {
    useLocation.setState({ isLoading: false });
    return {
      ...defaultLocation,
      error: error instanceof Error ? error.message : 'Failed to get location',
    };
  }
}
