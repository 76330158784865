import { faBolt } from '@awesome.me/kit-e1dfe1051a/icons/duotone/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/navigation';
import { getVehicleUrl } from '~/helpers/url';
import { Vehicle } from '~/server/db/schema';
interface ScoreProps {
  score: number | null;
  maxScore?: number;
  small?: boolean;
}

// Shared component for the score bar visualization
export function ScoreBar({
  score = 0,
  maxScore = 5,
  small = false
}: ScoreProps) {
  const percentage = Number(score) / maxScore * 100;
  const segments = Array.from({
    length: 5
  });
  return <div className="flex h-2 gap-0.5" data-sentry-component="ScoreBar" data-sentry-source-file="Score.tsx">
      {segments.map((_, i) => {
      const segmentPercentage = score === null || score === 0 ? 0 : Math.max(0, Math.min(100, percentage - i * 20 > 20 ? 100 : Math.max(0, percentage - i * 20) * 5));
      return <div key={i} className={`${small ? 'h-2' : 'h-3'} flex-1 overflow-hidden rounded-full ${score === null || score === 0 ? 'bg-light-backgroundPlainMedium' : 'bg-light-backgroundPurpleHeavy'}`} data-testid={`score-segment-${i}`}>
            {score !== null && score !== 0 && <div className="h-full bg-light-graphicsGreenButtons transition-all duration-300" style={{
          width: `${segmentPercentage}%`
        }} />}
          </div>;
    })}
    </div>;
}

// Shared component for displaying the score value
export function ScoreValue({
  score = 0,
  maxScore = 5
}: ScoreProps) {
  if (score === 0) {
    return <div className="flex items-baseline gap-2 pb-2">
        <span className="text-4xl font-regular text-light-textPlainQuaternary">
          Pending
        </span>
      </div>;
  }
  return <div className="flex items-baseline gap-2 pb-2" data-sentry-component="ScoreValue" data-sentry-source-file="Score.tsx">
      <span className="text-4xl font-regular text-light-textGreenPlainPrimary">
        {Number(score).toFixed(2)}
      </span>
      <span className="text-2xl text-light-textGreenPlainTertiary">
        / {maxScore}
      </span>
    </div>;
}

// Component for the summary score view
export function ScoreSummary({
  score = 0,
  maxScore = 5
}: ScoreProps) {
  return <div className="flex flex-col gap-2" data-sentry-component="ScoreSummary" data-sentry-source-file="Score.tsx">
      <div className="w-24">
        <ScoreBar score={score} maxScore={maxScore} small data-sentry-element="ScoreBar" data-sentry-source-file="Score.tsx" />
      </div>

      <div className="flex items-baseline gap-1 h-[28px]">
        {score === 0 ? <span className="text-sm font-semibold text-light-textPlainTertiary">
            Pending Recharged Score
          </span> : <div className="flex items-baseline gap-1">
            <span className="text-xl text-light-textGreenPlainPrimary">
              {Number(score).toFixed(1)}
            </span>
            <span className="text-xl text-light-textGreenPlainTertiary">/</span>
            <span className="text-xl text-light-textGreenPlainTertiary">
              {maxScore}
            </span>
            <span className="align-baseline text-sm pl-1 font-bold text-light-textGreenPlainSecondary">
              Recharged Score
            </span>
          </div>}
      </div>
    </div>;
}

// Component for the detailed score view
export function ScoreDetail({
  vehicle,
  score = 0,
  maxScore = 5
}: ScoreProps & {
  vehicle: Vehicle;
}) {
  const router = useRouter();
  return <div className="space-y-6" data-sentry-component="ScoreDetail" data-sentry-source-file="Score.tsx">
      <h2 className="text-2xl text-light-textPurplePlainPrimary font-semibold">
        EV Insights
      </h2>

      <div className={`relative overflow-hidden rounded-2xl border p-6 ${score === 0 ? 'bg-light-backgroundPlainLight' : 'bg-light-backgroundGreenLight'}`}>
        <div className="absolute right-0 top-0 h-full w-full" style={{
        backgroundImage: 'url(/bolt.svg)',
        backgroundPosition: 'right center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain'
      }} />

        <div className="relative z-10">
          <div className="mb-4 w-48">
            <ScoreBar score={score} maxScore={maxScore} data-sentry-element="ScoreBar" data-sentry-source-file="Score.tsx" />
          </div>

          <div className="flex flex-col sm:flex-row items-stretch justify-between">
            <div className="flex flex-col">
              <ScoreValue score={score} maxScore={maxScore} data-sentry-element="ScoreValue" data-sentry-source-file="Score.tsx" />

              <div className="mt-1 flex items-center gap-1">
                <span className={`text-xl inline-flex items-center ${score === 0 ? 'text-light-textPlainPrimary' : 'text-light-textGreenPlainSecondary'}`}>
                  <FontAwesomeIcon icon={faBolt} className="mr-1 inline-block" color="text-light-textGreenPlainPrimary" size="1x" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="Score.tsx" />
                  <span className="inline">Recharged Score</span>
                </span>
              </div>

              <p className={`text-sm ${score === 0 ? 'text-light-textPlainTertiary' : 'text-light-textGreenPlainSecondary'}`}>
                Your guide to an informed EV purchase, weighing
                <span className="hidden md:inline whitespace-pre-line">
                  {'\n'}
                </span>
                <span className="md:hidden"> </span>
                battery health, vehicle history, and market pricing.
              </p>
            </div>

            <div className="flex items-center sm:pb-4 pt-6 sm:pt-4">
              {score === 0 ? <div className="rounded-lg bg-light-backgroundPlainPure px-4 py-2 text-md font-medium text-light-textPlainQuaternary">
                  Coming soon
                </div> : <button onClick={() => router.push(`${getVehicleUrl(vehicle.make, vehicle.model, vehicle.vin)}/score-report`)} className="rounded-lg text-light-textPurplePlainPrimary bg-light-backgroundPlainPure px-4 py-2 text-md font-medium shadow-sm border border-light-strokeNormal transition-shadow hover:shadow-md">
                  View full report
                </button>}
            </div>
          </div>
        </div>
      </div>
    </div>;
}