'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
const categories = [{
  title: 'Sedans',
  image: '/images/categories/sedan.png',
  href: `/vehicles?vehicleType=${encodeURIComponent('Car')}`
}, {
  title: 'SUVs',
  image: '/images/categories/suv.png',
  href: `/vehicles?vehicleType=${encodeURIComponent('SUV')}`
}, {
  title: 'Trucks',
  image: '/images/categories/truck.png',
  href: `/vehicles?vehicleType=${encodeURIComponent('Pickup')}`
}, {
  title: 'Vans',
  image: '/images/categories/van.png',
  href: `/vehicles?vehicleType=${encodeURIComponent('Van')}`
}];
export function ShopYourWay() {
  const [selected, setSelected] = useState('Styles'); // Default selected state

  const handleButtonClick = (button: string) => {
    setSelected(button);
  };
  return <div className=" max-w-7xl  mx-auto" data-sentry-component="ShopYourWay" data-sentry-source-file="ShopYourWay.tsx">
      <h2 className="text-2xl md:text-4xl font-bold mb-4 md:mb-8">
        Shop Recharged your way
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {categories.map(category => <Link key={category.title} href={category.href} className="relative flex flex-col h-[280px] sm:h-[360px] rounded-xl overflow-hidden bg-light-backgroundPlainMedium">
            <div className="absolute inset-0 flex items-end">
              <Image src={category.image} alt={`${category.title} category - Click to view ${category.title} vehicles`} loading="lazy" width={720} // Set a specific width
          height={360} // Set a specific height
          className="object-cover" />
            </div>
            <div className="absolute inset-0" style={{
          background: 'linear-gradient(0deg, rgba(233, 231, 229, 0.00) 38.72%, #E9E7E5 86.82%)'
        }} />
            <h3 className="absolute top-4 left-4 text-3xl font-bold text-black">
              {category.title}
            </h3>
          </Link>)}
      </div>
    </div>;
}