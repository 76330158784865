'use client';

import { faChevronDown } from '@awesome.me/kit-e1dfe1051a/icons/duotone/solid';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { useState } from 'react';
import type { getFAQs } from '~/types/contentful';
type FAQItemProps = {
  faq: ReturnType<typeof getFAQs> extends Promise<(infer T)[]> ? T : never;
  isFirst?: boolean;
  isLast?: boolean;
};
export const FAQItem = ({
  faq,
  isFirst,
  isLast
}: FAQItemProps) => {
  const [expanded, setExpanded] = useState(false);
  return <div className={`bg-light-backgroundPlainPure text-light-textPurplePlainPrimary ${isFirst ? 'rounded-t-xl' : ''} ${isLast ? 'rounded-b-xl' : ''}`} data-sentry-component="FAQItem" data-sentry-source-file="FAQItem.tsx">
      <button onClick={() => setExpanded(!expanded)} className="w-full p-4 text-left flex justify-between items-center">
        <span>{faq.fields.question}</span>
        <FontAwesomeIcon icon={faChevronDown} className={`transition-transform ${expanded ? 'rotate-180' : ''}`} size="1x" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="FAQItem.tsx" />
      </button>
      {expanded && <div className="px-4 pb-4 prose prose-sm max-w-none">
          {documentToReactComponents(faq.fields.answer)}
          {faq.fields.blogreference?.fields && <Link href={`/learning/${faq.fields.blogreference.fields.category[0]?.fields?.slug}/${faq.fields.blogreference.fields.slug}`}>
              Read more
            </Link>}
        </div>}
    </div>;
};