import { type Vehicle } from '~/server/db/schema';

import { calculateMonthlyPayment } from './loan';

export function getFullName(vehicle: Vehicle): string {
  const year = vehicle.year ?? '';
  const make = vehicle.make ?? '';
  const model = vehicle.model ?? '';
  return [year, make, model].filter(Boolean).join(' ');
}

interface DisplayPriceOptions {
  includeTaxCredit: boolean;
  useMonthlyPayments: boolean;
  downPayment: number;
  creditScore: number;
  loanTermMonths: number;
}

export function getTaxCredit(vehicle: Vehicle) {
  if (!vehicle.fedTaxCredit) {
    return 0;
  }

  return Math.round(Math.min(4000, Number(vehicle.price) * 0.3));
}

export function getDisplayPrice(
  vehicle: Vehicle,
  options: DisplayPriceOptions,
): string {
  let price = Number(vehicle.price);

  if (options.includeTaxCredit) {
    const taxCredit = getTaxCredit(vehicle);
    price -= taxCredit;
  }

  if (options.useMonthlyPayments) {
    const { monthlyPayment } = calculateMonthlyPayment({
      vehiclePrice: price,
      downPayment: options.downPayment,
      creditScore: options.creditScore,
      loanTermMonths: options.loanTermMonths,
    });

    return `$${Math.round(monthlyPayment).toLocaleString()}/mo`;
  }

  return `$${Math.round(price).toLocaleString()}`;
}

export function getDisplayMileage(vehicle: Vehicle) {
  if (vehicle.mileage === null || vehicle.mileage === undefined) {
    return 'Mileage not available';
  }

  return `${vehicle.mileage.toLocaleString()} mi`;
}

export function getDisplayRange(vehicle: Vehicle) {
  if (!vehicle.rangeMiles) {
    return 'Range not available';
  }

  return `${vehicle.rangeMiles.toLocaleString()} mi range`;
}
