import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface LoanTermsState {
  showMonthlyPayment: boolean;
  downPayment: number;
  creditScore: number;
  loanTermMonths: number;
  setShowMonthlyPayment: (show: boolean) => void;
  setDownPayment: (amount: number) => void;
  setCreditScore: (score: number) => void;
  setLoanTermMonths: (months: number) => void;
}

const storage = typeof window !== 'undefined'
  ? createJSONStorage(() => window.localStorage)
  : createJSONStorage(() => ({
      getItem: () => null,
      setItem: () => {},
      removeItem: () => {},
    }));

export const useLoanTerms = create<LoanTermsState>()(
  persist(
    (set) => ({
      showMonthlyPayment: true,
      downPayment: 5000,
      creditScore: 740,
      loanTermMonths: 60,
      setShowMonthlyPayment: (show) => set({ showMonthlyPayment: show }),
      setDownPayment: (amount) => set({ downPayment: amount }),
      setCreditScore: (score) => set({ creditScore: score }),
      setLoanTermMonths: (months) => set({ loanTermMonths: months }),
    }),
    {
      name: 'loan-terms-storage',
      storage,
    },
  ),
);
