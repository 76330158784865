'use client';

import { createContext, useContext, useState, type ReactNode } from 'react';
import { logger } from '~/utils/logger';
interface ConsentState {
  analytics: boolean;
  marketing: boolean;
}
declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
    localStorage: Storage;
  }
}
interface ConsentContextType {
  consents: ConsentState;
  hasUserConsented: boolean;
  isCustomizing: boolean;
  isDoNotSell: boolean;
  updateAllConsents: (_newConsents: ConsentState) => void;
  openPreferences: () => void;
  closePreferences: () => void;
  setDoNotSell: (value: boolean) => void;
}
const ConsentContext = createContext<ConsentContextType | undefined>(undefined);
export const getInitialState = () => {
  try {
    if (typeof window === 'undefined' || !window.localStorage) {
      return {
        consents: {
          analytics: false,
          marketing: false
        },
        hasUserConsented: false,
        isDoNotSell: false
      };
    }
    const savedConsents = window.localStorage.getItem('userConsents');
    const doNotSell = window.localStorage.getItem('doNotSell') === 'true';
    return {
      consents: savedConsents ? JSON.parse(savedConsents) : {
        analytics: false,
        marketing: false
      },
      hasUserConsented: !!savedConsents,
      isDoNotSell: doNotSell
    };
  } catch (error) {
    logger.error(error);
    // Fallback to default state if any errors occur
    return {
      consents: {
        analytics: false,
        marketing: false
      },
      hasUserConsented: false,
      isDoNotSell: false
    };
  }
};
export function ConsentProvider({
  children
}: {
  children: ReactNode;
}) {
  const initialState = getInitialState();
  const [consents, setConsents] = useState<ConsentState>(initialState.consents);
  const [hasUserConsented, setHasUserConsented] = useState(initialState.hasUserConsented);
  const [isCustomizing, setIsCustomizing] = useState(false);
  const [isDoNotSell, setIsDoNotSell] = useState(initialState.isDoNotSell);
  const updateAllConsents = (_newConsents: ConsentState) => {
    if (!isDoNotSell) {
      setConsents(_newConsents);
      setHasUserConsented(true);
      if (typeof window !== 'undefined' && window.localStorage) {
        window.localStorage.setItem('userConsents', JSON.stringify(_newConsents));

        // Push consent state to GTM, respecting Do Not Sell
        window.dataLayer?.push({
          event: 'consent_update',
          analytics_storage: _newConsents.analytics ? 'granted' : 'denied',
          ad_storage: _newConsents.marketing ? 'granted' : 'denied',
          wait_for_update: 500
        });
      }
    } else {
      window.dataLayer?.push({
        event: 'consent_update',
        analytics_storage: 'denied',
        ad_storage: 'denied',
        wait_for_update: 500
      });
    }
  };
  const openPreferences = () => setIsCustomizing(true);
  const closePreferences = () => setIsCustomizing(false);
  const setDoNotSell = (value: boolean) => {
    setIsDoNotSell(value);
    if (typeof window !== 'undefined' && window.localStorage) {
      window.localStorage.setItem('doNotSell', value.toString());
    }
  };
  return <ConsentContext.Provider value={{
    consents,
    hasUserConsented,
    isCustomizing,
    isDoNotSell,
    updateAllConsents,
    openPreferences,
    closePreferences,
    setDoNotSell
  }} data-sentry-element="unknown" data-sentry-component="ConsentProvider" data-sentry-source-file="ConsentContext.tsx">
      {children}
    </ConsentContext.Provider>;
}
export function useConsent() {
  const context = useContext(ConsentContext);
  if (context === undefined) {
    throw new Error('useConsent must be used within a ConsentProvider');
  }
  return context;
}