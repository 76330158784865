import typography from '@tailwindcss/typography';
import type { Config } from 'tailwindcss';
import animate from 'tailwindcss-animate';

import { colorTokens, colorAssignments } from './src/styles/colors';

const config: Config = {
    darkMode: ['class'],
    content: [
    './src/pages/**/*.{js,ts,jsx,tsx,mdx}',
    './src/components/**/*.{js,ts,jsx,tsx,mdx}',
    './src/app/**/*.{js,ts,jsx,tsx,mdx}',
  ],
  theme: {
  	fontSize: {
  		xs: [
  			'0.75rem',
  			'1rem'
  		],
  		sm: [
  			'0.875rem',
  			'1.25rem'
  		],
  		base: [
  			'1rem',
  			'1.5rem'
  		],
  		lg: [
  			'1.125rem',
  			'1.75rem'
  		],
  		xl: [
  			'1.25rem',
  			'1.75rem'
  		],
  		'2xl': [
  			'1.5rem',
  			'2rem'
  		],
  		'3xl': [
  			'1.875rem',
  			'2.25rem'
  		],
  		'4xl': [
  			'2.25rem',
  			'2.5rem'
  		],
  		'5xl': [
  			'3rem',
  			'1'
  		],
  		'6xl': [
  			'3.75rem',
  			'1'
  		],
  		'7xl': [
  			'4.5rem',
  			'1'
  		],
  		'8xl': [
  			'6rem',
  			'1'
  		],
  		'9xl': [
  			'8rem',
  			'1'
  		]
  	},
  	extend: {
  		fontFamily: {
  			sans: [
  				'var(--font-figtree)'
  			]
  		},
  		colors: {
                ...colorTokens,
                ...colorAssignments,
  			background: 'hsl(var(--background))',
  			foreground: 'hsl(var(--foreground))',
  			card: {
  				DEFAULT: 'hsl(var(--card))',
  				foreground: 'hsl(var(--card-foreground))'
  			},
  			popover: {
  				DEFAULT: 'hsl(var(--popover))',
  				foreground: 'hsl(var(--popover-foreground))'
  			},
  			primary: {
  				DEFAULT: 'hsl(var(--primary))',
  				foreground: 'hsl(var(--primary-foreground))'
  			},
  			secondary: {
  				DEFAULT: 'hsl(var(--secondary))',
  				foreground: 'hsl(var(--secondary-foreground))'
  			},
  			muted: {
  				DEFAULT: 'hsl(var(--muted))',
  				foreground: 'hsl(var(--muted-foreground))'
  			},
  			accent: {
  				DEFAULT: 'hsl(var(--accent))',
  				foreground: 'hsl(var(--accent-foreground))'
  			},
  			destructive: {
  				DEFAULT: 'hsl(var(--destructive))',
  				foreground: 'hsl(var(--destructive-foreground))'
  			},
  			border: 'hsl(var(--border))',
  			input: 'hsl(var(--input))',
  			ring: 'hsl(var(--ring))',
  			chart: {
  				'1': 'hsl(var(--chart-1))',
  				'2': 'hsl(var(--chart-2))',
  				'3': 'hsl(var(--chart-3))',
  				'4': 'hsl(var(--chart-4))',
  				'5': 'hsl(var(--chart-5))'
  			}
  		},
  		keyframes: {
  			'fade-in': {
  				'0%': {
  					opacity: '0'
  				},
  				'100%': {
  					opacity: '1'
  				}
  			}
  		},
  		animation: {
  			'fade-in': 'fade-in 0.3s ease-out forwards'
  		},
  		borderRadius: {
  			lg: 'var(--radius)',
  			md: 'calc(var(--radius) - 2px)',
  			sm: 'calc(var(--radius) - 4px)'
  		}
  	},
  	screens: {
  		sm: '640px',
  		md: '1024px',
  		lg: '1280px',
  		xl: '1440px',
  		'2xl': '1920px'
  	}
  },
  plugins: [typography, animate],
};

export default config;
