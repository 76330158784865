'use client';

import { ReactNode } from 'react';
import { useLocation } from '~/store/geolocation';
export function LocationProvider({
  children
}: {
  children: ReactNode;
}) {
  useLocation.getState().getLocationIfNeeded();
  return children;
}