export function getBaseInterestRateByCredit(creditScore: number): number {
  const interestRateMap = new Map<number, number>([
    [751, 5.74], // Superprime
    [651, 7.99], // Prime
    [601, 13.99], // Nonprime
    [600, 19.99], // Subprime
  ]);

  for (const [threshold, rate] of interestRateMap) {
    if (creditScore >= threshold) {
      return rate;
    }
  }

  // Default to highest rate instead of throwing
  return 15.77;
}

export function calculateMonthlyPayment(parameters: {
  vehiclePrice: number;
  downPayment: number;
  creditScore: number;
  loanTermMonths: number;
}): {
  monthlyPayment: number;
  interestRate: number;
} {
  // Sanitize inputs
  const sanitizedPrice = Math.max(0, parameters.vehiclePrice);
  const sanitizedDownPayment = Math.max(0, parameters.downPayment);

  // Ensure down payment doesn't exceed price
  const finalDownPayment = Math.min(sanitizedDownPayment, sanitizedPrice);

  const loanPrincipal = sanitizedPrice - finalDownPayment;
  const interestRate = getBaseInterestRateByCredit(parameters.creditScore);
  const monthlyRate = interestRate / 12 / 100;

  // Handle edge cases
  if (parameters.loanTermMonths <= 0 || loanPrincipal <= 0) {
    return {
      monthlyPayment: 0,
      interestRate: interestRate,
    };
  }

  const monthlyPayment =
    loanPrincipal *
    ((monthlyRate * (1 + monthlyRate) ** parameters.loanTermMonths) /
      ((1 + monthlyRate) ** parameters.loanTermMonths - 1));

  return {
    monthlyPayment: monthlyPayment,
    interestRate: interestRate,
  };
}
