'use client';

import { faArrowLeft, faArrowRight } from '@awesome.me/kit-e1dfe1051a/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, TouchEvent, useEffect, ReactNode, useRef, useCallback } from 'react';
import { useMediaQuery } from '~/hooks/useMediaQuery';
interface CarouselProps<T extends Record<string, unknown>> {
  items: T[];
  renderItem: (_item: T) => ReactNode;
  className?: string;
  itemsPerBreakpoint?: {
    base?: number;
    sm?: number;
    md?: number;
    xl?: number;
  };
}
export function Carousel<T extends Record<string, unknown>>({
  items,
  renderItem,
  className = '',
  itemsPerBreakpoint = {
    base: 1,
    sm: 2,
    md: 3,
    xl: 4
  }
}: CarouselProps<T>) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchStartY, setTouchStartY] = useState<number | null>(null);
  const [touchDelta, setTouchDelta] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [touchStartTime, setTouchStartTime] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const hasInitialized = useRef(false);
  const [isBrowser, setIsBrowser] = useState(false);
  const isSm = useMediaQuery('sm');
  const isMd = useMediaQuery('md');
  const isXl = useMediaQuery('xl');
  const itemsPerPage = !hasInitialized.current ? itemsPerBreakpoint.xl ?? 4 : isXl ? itemsPerBreakpoint.xl ?? 4 : isMd ? itemsPerBreakpoint.md ?? 3 : isSm ? itemsPerBreakpoint.sm ?? 2 : itemsPerBreakpoint.base ?? 1;
  const totalPages = Math.ceil(items.length / itemsPerPage);
  const pages = Array.from({
    length: totalPages
  }, (_, i) => items.slice(i * itemsPerPage, (i + 1) * itemsPerPage));
  const carouselRef = useRef<HTMLDivElement>(null);
  const handleTouchStart = (e: TouchEvent) => {
    setTouchStart(e.touches[0]?.clientX ?? 0);
    setTouchStartY(e.touches[0]?.clientY ?? 0);
    setTouchStartTime(Date.now());
    setIsDragging(true);
  };
  const handleTouchMove = (e: TouchEvent) => {
    if (!touchStart || !touchStartY || !isDragging) return;
    const currentTouch = e.touches[0]?.clientX ?? 0;
    const currentTouchY = e.touches[0]?.clientY ?? 0;
    const deltaX = touchStart - currentTouch;
    const deltaY = Math.abs(touchStartY - currentTouchY);
    if (deltaY > 30) {
      resetTouchState();
      return;
    }
    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      e.preventDefault();
      const percentageMoved = deltaX / window.innerWidth * 100;
      if (Math.abs(percentageMoved) > 5) {
        setTouchDelta(Math.max(Math.min(percentageMoved, 100), -100));
      }
    }
  };
  const handleTouchEnd = () => {
    if (!touchStart || !isDragging) {
      resetTouchState();
      return;
    }
    const touchDuration = Date.now() - touchStartTime;
    const minSwipeDistance = 15;
    if (touchDuration < 500 && Math.abs(touchDelta) > minSwipeDistance) {
      if (touchDelta > 0 && currentIndex < totalPages - 1) {
        setCurrentIndex(prev => prev + 1);
      } else if (touchDelta < 0 && currentIndex > 0) {
        setCurrentIndex(prev => prev - 1);
      }
    }
    resetTouchState();
  };
  const resetTouchState = () => {
    setTouchStart(null);
    setTouchStartY(null);
    setTouchDelta(0);
    setIsDragging(false);
  };
  const handleWheel = useCallback((e: WheelEvent) => {
    // Only handle horizontal scrolling (shift + scroll or touchpad gestures)
    if (e.deltaX !== 0) {
      // Check if the event target is within our specific carousel
      const target = e.target as HTMLElement;
      if (!carouselRef.current?.contains(target)) return;
      e.preventDefault();

      // Detect direction of scroll
      const direction = e.deltaX > 0 ? 1 : -1;

      // Add safety checks for index boundaries
      const nextIndex = currentIndex + direction;
      if (nextIndex >= 0 && nextIndex < totalPages) {
        setCurrentIndex(nextIndex);
      }
    }
  }, [currentIndex, totalPages]);
  useEffect(() => {
    if (typeof window === 'undefined') return;
    const wheelHandler = (e: WheelEvent) => handleWheel(e);
    document.addEventListener('wheel', wheelHandler, {
      passive: false
    });
    return () => {
      document.removeEventListener('wheel', wheelHandler);
    };
  }, [currentIndex, totalPages, handleWheel]);
  useEffect(() => {
    setIsLoading(false);
  }, []);
  useEffect(() => {
    if (!hasInitialized.current) {
      hasInitialized.current = true;
    }
    return () => {
      hasInitialized.current = false;
    };
  }, []);
  useEffect(() => {
    setIsBrowser(true);
  }, []);
  if (isLoading) {
    return <div className={`${className} mb-12`}>
        <div className="grid grid-cols-1 gap-6 py-3 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
          {Array.from({
          length: 4
        }).map((_, i) => <div key={i} className="animate-pulse">
              <div className="aspect-[4/3] w-full rounded-t-xl bg-light-backgroundPlainHeavy" />
              <div className="p-4 space-y-3">
                <div className="h-6 w-3/4 rounded bg-light-backgroundPlainHeavy" />
                <div className="h-4 w-1/2 rounded bg-light-backgroundPlainHeavy" />
                <div className="h-4 w-1/4 rounded bg-light-backgroundPlainHeavy" />
              </div>
            </div>)}
        </div>
      </div>;
  }
  return <div className={`${className} mb-12`} data-sentry-component="Carousel" data-sentry-source-file="Carousel.tsx">
      <div ref={carouselRef} className="relative mb-2 overflow-hidden touch-pan-y carousel-container" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onTouchCancel={resetTouchState}>
        {pages.map((pageItems, idx) => <div key={idx} className={`
              w-full
              ${idx === currentIndex ? 'relative' : 'absolute inset-0'} 
              transform
              ${isBrowser ? 'transition-transform duration-300 ease-in-out' : 'transition-none'}
              ${idx === currentIndex ? 'translate-x-0' : ''}
              ${idx < currentIndex ? '-translate-x-full' : ''}
              ${idx > currentIndex ? 'translate-x-full' : ''}
              ${isDragging ? 'transition-none' : ''}
            `}>
            <div className="grid grid-cols-1 gap-6 py-3 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
              {pageItems.map((item, i) => <div key={i}>{renderItem(item)}</div>)}
            </div>
          </div>)}
      </div>

      <div className="flex justify-end gap-2">
        <button onClick={() => setCurrentIndex(prev => prev - 1)} disabled={currentIndex === 0 || isDragging} aria-label="Previous page" className="flex h-8 w-8 items-center justify-center rounded-full bg-light-backgroundPlainHeavy hover:shadow-lg disabled:opacity-30 disabled:cursor-not-allowed">
          <FontAwesomeIcon icon={faArrowLeft} size="1x" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="Carousel.tsx" />
        </button>
        <button onClick={() => setCurrentIndex(prev => prev + 1)} disabled={currentIndex === totalPages - 1 || isDragging} aria-label="Next page" className="flex h-8 w-8 items-center justify-center rounded-full bg-light-backgroundPlainHeavy hover:shadow-lg disabled:opacity-30 disabled:cursor-not-allowed">
          <FontAwesomeIcon icon={faArrowRight} size="1x" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="Carousel.tsx" />
        </button>
      </div>
    </div>;
}