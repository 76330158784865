'use client';

import { useEffect } from 'react';
import { logger } from '~/utils/logger';
export default function Error({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    logger.error('Error', {
      error
    });
  }, [error]);
  return <div className="flex min-h-screen flex-col items-center justify-center" data-sentry-component="Error" data-sentry-source-file="error.tsx">
      <div className="text-center">
        <h2 className="mb-4 text-2xl font-bold">Something went wrong!</h2>
        <p className="text-light-textPlainSecondary mb-8">{error.message}</p>
        <button onClick={() => {
        reset();
      }} className="rounded-md bg-blue-500 px-4 py-2 text-light-textPlainInverse transition hover:bg-blue-600">
          Try again
        </button>
      </div>
    </div>;
}