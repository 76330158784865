import { useState, useEffect } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config';

// Default breakpoints in case config fails to load
const DEFAULT_SCREENS = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
};

const fullConfig = resolveConfig(tailwindConfig);
const screens = fullConfig?.theme?.screens || DEFAULT_SCREENS;

type ScreenSize = keyof typeof screens;

export function useMediaQuery(screen: ScreenSize) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const query = `(min-width: ${screens[screen]})`;

    const mediaQuery = window.matchMedia(query);

    setMatches(mediaQuery.matches);

    const listener = (e: MediaQueryListEvent) => {
      setMatches(e.matches);
    };

    mediaQuery.addEventListener('change', listener);
    return () => mediaQuery.removeEventListener('change', listener);
  }, [screen]);

  return matches;
}
