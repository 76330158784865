'use client';

import { faMapPin, faMap } from '@awesome.me/kit-e1dfe1051a/icons/duotone/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { useState } from 'react';
import { logger } from '~/utils/logger';
export const VisitUs = ({
  title,
  tagline
}: {
  title: string;
  tagline: string;
}) => {
  const [showCopied, setShowCopied] = useState(false);
  const address = '8410 W Broad Street, Richmond, VA 23294';
  const handleCopyAddress = async () => {
    try {
      // Check if running on iOS
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      if (isIOS) {
        // Create a temporary span element
        const span = document.createElement('span');
        span.textContent = address;

        // Position it without affecting layout
        span.style.cssText = 'position:fixed;font-size:12px;opacity:0;';
        document.body.appendChild(span);

        // Select the text
        const range = document.createRange();
        range.selectNode(span);
        const selection = window.getSelection();
        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }

        // Try to copy
        const result = document.execCommand('copy');

        // Clean up
        document.body.removeChild(span);
        if (selection) {
          selection.removeAllRanges();
        }
        if (!result) {
          throw new Error('Copy command failed');
        }
      } else if ('clipboard' in navigator) {
        // For non-iOS devices, use the modern clipboard API
        await navigator.clipboard.writeText(address);
      } else {
        throw new Error('No clipboard access method available');
      }

      // Show success state
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    } catch (err) {
      logger.error('Failed to copy text', {
        err
      });
      // Optionally show user-friendly error message
    }
  };
  return <section className="flex flex-col items-center text-center px-4 mb-14 sm:mb-20" data-sentry-component="VisitUs" data-sentry-source-file="VisitUs.tsx">
      <div className="mb-4 relative inline-block">
        <FontAwesomeIcon icon={faMap} className="text-light-graphicsPurpleIcons text-5xl sm:text-8xl opacity-10" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="VisitUs.tsx" />
        <FontAwesomeIcon icon={faMapPin} className="text-light-graphicsPurpleIcons text-3xl sm:text-5xl absolute left-1/2 top-1/4 -translate-x-1/2 -translate-y-1/2" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="VisitUs.tsx" />
      </div>

      <h2 className="text-2xl md:text-3xl font-bold mb-4 text-light-textPurplePlainPrimary max-w-lg">
        {title}
      </h2>

      <p className="text-base text-light-textPlainPrimary mb-4">{tagline}</p>

      <p className="text-base text-light-textPlainPrimary mb-4">{address}</p>

      <div className="flex flex-col items-center min-h-[80px]">
        <div className="flex gap-4 mb-2">
          <Link href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`} target="_blank" rel="noopener noreferrer" className="bg-light-textPurplePlainPrimary text-light-textPlainInverse hover:bg-gray-800 px-3 py-2 rounded-xl text-base font-bold" data-sentry-element="Link" data-sentry-source-file="VisitUs.tsx">
            Get directions
          </Link>

          <button onClick={handleCopyAddress} className="bg-light-backgroundPlainPure text-light-textPurplePlainPrimary hover:bg-gray-100 px-3 py-2 rounded-xl text-base font-bold border border-light-strokeNormal">
            Copy address
          </button>
        </div>

        <div className={`
            transition-opacity duration-200 ease-in-out text-light-graphicsGreenButtons text-sm
            ${showCopied ? 'opacity-100' : 'opacity-0'}
          `} aria-live="polite">
          {showCopied && '✓ Address copied to clipboard'}
        </div>
      </div>
    </section>;
};