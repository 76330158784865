'use client';

import { faStar } from '@awesome.me/kit-e1dfe1051a/icons/duotone/solid';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Entry } from 'contentful';
import Image from 'next/image';
import Link from 'next/link';
import { Suspense } from 'react';
import { api } from '~/trpc/react';
import { Review } from '~/types/contentful';
import { Carousel } from '../Carousel';
function GoogleReviewsFooter() {
  const {
    data: googleData,
    error
  } = api.googlePlaces.getReviews.useQuery(undefined, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
  if (error) return null;
  if (!googleData) return null;
  return <div className="flex items-center -mt-20" data-sentry-component="GoogleReviewsFooter" data-sentry-source-file="ReviewsCarousel.tsx">
      <div className="hidden sm:block flex-1" />
      <div className="flex flex-col items-start sm:items-center gap-2">
        <div className="flex items-center gap-2">
          <Image src="/google-g.svg" alt="Google" width={24} height={24} data-sentry-element="Image" data-sentry-source-file="ReviewsCarousel.tsx" />
          <div className="flex items-center gap-1">
            {[...Array(5)].map((_, i) => <FontAwesomeIcon key={i} icon={faStar} className="h-5 w-5 text-[#FABB05]" />)}
          </div>
        </div>
        <Link href={googleData.reviewsUrl ?? '#'} target="_blank" rel="noopener noreferrer" className="text-sm text-textPlainSecondary hover:text-light-textPurplePlainPrimary " data-sentry-element="Link" data-sentry-source-file="ReviewsCarousel.tsx">
          <span className="underline">{googleData.rating} stars on Google</span>{' '}
          from {googleData.user_ratings_total} reviews
        </Link>
      </div>
      <div className="hidden sm:block flex-1" />
    </div>;
}
export function ReviewsCarousel({
  reviews
}: {
  reviews: Entry<Review>[];
}) {
  return <div className="mx-auto max-w-7xl" data-sentry-component="ReviewsCarousel" data-sentry-source-file="ReviewsCarousel.tsx">
      <h2 className="mb-4 md:mb-6 text-2xl md:text-4xl font-bold text-center">
        5.0 stars from 100+ reviews
      </h2>
      <div className="carousel-container">
        <Carousel items={reviews} itemsPerBreakpoint={{
        base: 1,
        sm: 2,
        md: 3,
        xl: 4
      }} renderItem={review => <div className="h-[450px] rounded-3xl border border-borderPrimary bg-light-backgroundPlainPure shadow-sm transition-shadow hover:shadow-md">
              <div className="flex h-full flex-col">
                <div className="h-[200px] w-full">
                  <Image src={review.fields.image ? `https:${review.fields.image?.fields.file.url}` : '/images/no-review-image.svg'} alt={review.fields.name} width={300} height={200} className="h-full w-full rounded-t-3xl object-cover" />
                </div>
                <div className="flex flex-1 flex-col p-6">
                  <div className="mb-2 flex items-center justify-between">
                    <div className="flex gap-1">
                      {[...Array(5)].map((_, i) => <FontAwesomeIcon key={i} icon={faStar} className="text-[#FABB05]" size="1x" />)}
                    </div>
                    <FontAwesomeIcon icon={faGoogle} className="text-textPlainQuaternary" size="1x" />
                  </div>
                  <Link href={review.fields.reviewurl ?? '#'} target="_blank" rel="noopener noreferrer" className="mb-2 font-semibold underline opacity-100 transition-opacity hover:opacity-80">
                    {review.fields.name}
                  </Link>
                  <p className="line-clamp-6 flex-1 text-textPlainSecondary">
                    {review.fields.reviewtext}
                  </p>
                </div>
              </div>
            </div>} data-sentry-element="Carousel" data-sentry-source-file="ReviewsCarousel.tsx" />
      </div>
      <Suspense fallback={null} data-sentry-element="Suspense" data-sentry-source-file="ReviewsCarousel.tsx">
        <GoogleReviewsFooter data-sentry-element="GoogleReviewsFooter" data-sentry-source-file="ReviewsCarousel.tsx" />
      </Suspense>
    </div>;
}