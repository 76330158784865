'use client';

export function BackgroundBolt({
  image = '/sideways-bolt.svg',
  position = '-10rem',
  fill = '#FBFAFF',
  invert = false
}: {
  image?: string;
  position?: string;
  fill?: string;
  invert?: boolean;
}) {
  return <div className="absolute inset-0 isolate -z-10" style={{
    maskImage: `url(${image})`,
    WebkitMaskImage: `url(${image})`,
    maskPosition: `center ${position}`,
    WebkitMaskPosition: `center ${position}`,
    maskRepeat: 'no-repeat',
    WebkitMaskRepeat: 'no-repeat',
    maskSize: '1625px 3000px',
    WebkitMaskSize: '1625px 3000px',
    backgroundColor: fill,
    opacity: 1,
    pointerEvents: 'none',
    transform: invert ? 'scaleX(-1)' : undefined
  }} data-sentry-component="BackgroundBolt" data-sentry-source-file="BackgroundBolt.tsx" />;
}