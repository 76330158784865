'use client';

import { faChevronLeft, faChevronRight } from '@awesome.me/kit-e1dfe1051a/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';
import { useState, TouchEvent } from 'react';
import { AutoTrack } from '~/components/analytics/AutoTrack';
import { getVehicleUrl } from '~/helpers/url';
import { getDisplayRange, getFullName } from '~/helpers/vehicle';
import { type Vehicle } from '~/server/db/schema';
import { ScoreSummary } from '../Score';
import { PriceDisplay } from './PriceDisplay';
type VehicleCardProps = {
  vehicle: Vehicle;
  showMonthlyPayment?: boolean;
  includeTaxCredit?: boolean;
  showTaxCredit?: boolean;
  isPriority?: boolean;
  disableCarousel?: boolean;
};
export function VehicleCard({
  vehicle,
  showMonthlyPayment,
  includeTaxCredit,
  showTaxCredit = false,
  isPriority = false,
  disableCarousel = false
}: VehicleCardProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchStartY, setTouchStartY] = useState<number | null>(null);
  const [touchDelta, setTouchDelta] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  // If carousel is disabled, only use the first image
  const images = disableCarousel ? (vehicle.images || []).slice(0, 1) : vehicle.images || [];

  // Transform the image URLs
  const transformImageUrl = (url: string) => {
    return url.replace('dealerslink.s3.amazonaws.com/vehicles', 'images.recharged.com');
  };

  // Use transformed URL for first image
  const firstImage = images[0]?.url ? transformImageUrl(images[0].url) : '/placeholder.svg';

  // Transform URLs for rest images
  const restImages = images.slice(1).map(img => ({
    ...img,
    url: transformImageUrl(img.url)
  }));
  const vehicleName = `${vehicle.year} ${vehicle.make} ${vehicle.model}`;
  const handleTouchStart = (e: TouchEvent) => {
    if (images.length <= 1 || disableCarousel) return;
    setTouchStart(e.touches[0]?.clientX ?? 0);
    setTouchStartY(e.touches[0]?.clientY ?? 0);
    setIsDragging(true);
  };
  const handleTouchMove = (e: TouchEvent) => {
    if (images.length <= 1 || disableCarousel) return;
    if (!touchStart || !touchStartY || !isDragging) return;
    const currentTouch = e.touches[0]?.clientX ?? 0;
    const currentTouchY = e.touches[0]?.clientY ?? 0;
    const deltaX = touchStart - currentTouch;
    const deltaY = Math.abs(touchStartY - currentTouchY);
    if (deltaY > 30) {
      setTouchDelta(0);
      setIsDragging(false);
      return;
    }
    const percentageMoved = deltaX / window.innerWidth * 100;
    if (Math.abs(percentageMoved) > 5) {
      setTouchDelta(Math.max(Math.min(percentageMoved, 100), -100));
    }
  };
  const handleTouchEnd = () => {
    if (images.length <= 1 || disableCarousel) return;
    if (!touchStart || !isDragging) return;
    const minSwipeDistance = 15;
    if (Math.abs(touchDelta) > minSwipeDistance) {
      if (touchDelta > 0 && currentImageIndex < images.length - 1) {
        setCurrentImageIndex(prev => prev + 1);
      } else if (touchDelta < 0 && currentImageIndex > 0) {
        setCurrentImageIndex(prev => prev - 1);
      }
    }

    // Reset all touch states
    setTouchStart(null);
    setTouchStartY(null);
    setTouchDelta(0);
    setIsDragging(false);
  };
  const previousImage = () => {
    setCurrentImageIndex(prev => Math.max(0, prev - 1));
  };
  const nextImage = () => {
    setCurrentImageIndex(prev => Math.min(images.length - 1, prev + 1));
  };
  const shouldShowTaxCredit = includeTaxCredit ?? showTaxCredit;
  const urlPath = getVehicleUrl(vehicle.make, vehicle.model, vehicle.vin);
  return <AutoTrack componentName="VehicleCard" data={{
    make: vehicle.make,
    model: vehicle.model,
    year: vehicle.year,
    price: vehicle.price,
    vin: vehicle.vin,
    trim: vehicle.trim,
    mileage: vehicle.mileage,
    hasImages: vehicle.images?.length > 0
  }} data-sentry-element="AutoTrack" data-sentry-component="VehicleCard" data-sentry-source-file="VehicleCard.tsx">
      <Link href={urlPath} data-sentry-element="Link" data-sentry-source-file="VehicleCard.tsx">
        <div className="relative h-full w-full overflow-hidden rounded-xl border border-light-strokeNormal bg-light-backgroundPlainPure transition-shadow hover:shadow-lg" data-testid={`vehicle-card-${vehicle.id}`}>
          {vehicle.fedTaxCredit && <div className="absolute left-2 top-2 z-10 rounded-lg bg-light-graphicsGreenBackgrounds px-2 py-1 text-sm font-medium text-light-textPlainPrimary">
              EV tax credit
            </div>}

          <div className="relative aspect-[4/3] w-full overflow-hidden rounded-t-xl group" {...images.length > 1 && !disableCarousel ? {
          onTouchStart: handleTouchStart,
          onTouchMove: handleTouchMove,
          onTouchEnd: handleTouchEnd
        } : {}}>
            <Image src={firstImage} alt={images.length > 0 ? vehicleName : 'Vehicle placeholder'} fill className="absolute object-cover" sizes="(max-width: 640px) 100vw, (max-width: 768px) 50vw, 33vw" priority={isPriority} loading={isPriority ? 'eager' : 'lazy'} fetchPriority={isPriority ? 'high' : 'auto'} quality={75} placeholder={vehicle.images?.[0]?.blurDataUrl ? 'blur' : 'empty'} {...vehicle.images?.[0]?.blurDataUrl && {
            blurDataURL: vehicle.images[0].blurDataUrl
          }} data-sentry-element="Image" data-sentry-source-file="VehicleCard.tsx" />

            {/* Rest images only load if carousel enabled and after first image */}
            {!disableCarousel && restImages.map((image, index) => <Image key={image.url} src={image.url} alt={`${vehicleName} view ${index + 2}`} fill className="absolute object-cover transition-transform duration-300 ease-in-out" sizes="(max-width: 640px) 100vw, (max-width: 768px) 50vw, 33vw" loading="lazy" fetchPriority="low" quality={75} style={{
            transform: `translateX(${(index + 1 - currentImageIndex) * 100}%)`
          }} />)}

            {!disableCarousel && images.length > 1 && <>
                <button onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              previousImage();
            }} onTouchEnd={e => {
              e.preventDefault();
              e.stopPropagation();
              previousImage();
            }} className="absolute left-2 top-1/2 -translate-y-1/2 rounded-full bg-light-systemBackgroundBlack/20 w-8 h-8 flex items-center justify-center hover:bg-light-systemBackgroundBlack/40 touch-none" aria-label="Previous image">
                  <FontAwesomeIcon icon={faChevronLeft} className="text-light-textPlainInverse" size="1x" />
                </button>
                <button onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              nextImage();
            }} onTouchEnd={e => {
              e.preventDefault();
              e.stopPropagation();
              nextImage();
            }} className="absolute right-2 top-1/2 -translate-y-1/2 rounded-full bg-light-systemBackgroundBlack/20 w-8 h-8 flex items-center justify-center hover:bg-light-systemBackgroundBlack/40 touch-none" aria-label="Next image">
                  <FontAwesomeIcon icon={faChevronRight} className="text-light-textPlainInverse" size="1x" />
                </button>
              </>}

            {!disableCarousel && images.length > 1 && <div className="absolute bottom-2 left-1/2 flex -translate-x-1/2 gap-1 items-center">
                {images.length > 5 ? <>
                    <button onClick={e => {
                e.preventDefault();
                setCurrentImageIndex(Math.max(0, currentImageIndex - 1));
              }} className={`h-1 w-1 rounded-full transition-all ${currentImageIndex === 0 ? 'bg-white' : 'bg-white/40'}`} aria-label="First images" />
                    {[...Array(3)].map((_, i) => {
                const dotIndex = currentImageIndex + i - 1;
                if (dotIndex < 0 || dotIndex >= images.length) return null;
                return <button key={dotIndex} onClick={e => {
                  e.preventDefault();
                  setCurrentImageIndex(dotIndex);
                }} className={`h-1.5 w-1.5 rounded-full transition-all ${dotIndex === currentImageIndex ? 'bg-white w-3' : 'bg-white/60'}`} aria-label={`Image ${dotIndex + 1}`} />;
              })}
                    <button onClick={e => {
                e.preventDefault();
                setCurrentImageIndex(Math.min(images.length - 1, currentImageIndex + 1));
              }} className={`h-1 w-1 rounded-full transition-all ${currentImageIndex === images.length - 1 ? 'bg-white' : 'bg-white/40'}`} aria-label="Last images" />
                  </> : images.map((_, index) => <button key={index} onClick={e => {
              e.preventDefault();
              setCurrentImageIndex(index);
            }} className={`h-1.5 w-1.5 rounded-full transition-all ${index === currentImageIndex ? 'bg-white w-3' : 'bg-white/60'}`} aria-label={`Go to image ${index + 1}`} />)}
              </div>}
          </div>

          <div className="group p-4">
            <h3 className="mb-1 text-xl font-bold text-light-textPurplePlainPrimary">
              {getFullName(vehicle)}
            </h3>

            <div className="mb-3 flex items-center overflow-hidden text-sm text-light-textPlainTertiary">
              {vehicle.trim && <>
                  <span className="font-bold md:text-base truncate inline-block max-w-[130px]">
                    {vehicle.trim}
                  </span>
                  <span className="font-light text-light-textPlainQuaternary mx-1">
                    •
                  </span>
                </>}
              {vehicle.mileage && <span className="text-xs md:text-sm text-nowrap">
                  {(vehicle.mileage % 1000 < 750 ? Math.floor : Math.ceil)(vehicle.mileage / 1000)}
                  K mi
                </span>}
              {vehicle.rangeMiles && <>
                  <span className="font-light text-light-textPlainQuaternary mx-1">
                    •
                  </span>
                  <span className="text-xs md:text-sm text-nowrap">
                    {getDisplayRange(vehicle)}
                  </span>
                </>}
            </div>

            <ScoreSummary score={Number(vehicle.score)} data-sentry-element="ScoreSummary" data-sentry-source-file="VehicleCard.tsx" />

            <div className="mt-2">
              <PriceDisplay vehicle={vehicle} showMonthlyPayment={showMonthlyPayment} includeTaxCredit={shouldShowTaxCredit} variant="card" data-sentry-element="PriceDisplay" data-sentry-source-file="VehicleCard.tsx" />
            </div>
          </div>
        </div>
      </Link>
    </AutoTrack>;
}