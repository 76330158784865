'use client';

import { useState, useEffect, MouseEvent } from 'react';
import { useConsent } from './ConsentContext';
export function ConsentBanner() {
  const {
    consents,
    updateAllConsents,
    hasUserConsented,
    isCustomizing,
    openPreferences,
    closePreferences,
    isDoNotSell
  } = useConsent();
  const [tempConsents, setTempConsents] = useState(consents);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setTempConsents(consents);
    setIsLoaded(true);
  }, [consents]);
  if (!isLoaded) {
    return null;
  }
  if (hasUserConsented && !isCustomizing) {
    return null;
  }
  const handleAccept = (e: MouseEvent) => {
    e.preventDefault();
    try {
      updateAllConsents({
        analytics: true,
        marketing: true
      });
    } catch {
      // Error handling could be added here if needed
    }
  };
  const handleReject = (e: MouseEvent) => {
    e.preventDefault();
    try {
      updateAllConsents({
        analytics: false,
        marketing: false
      });
    } catch {
      // Error handling could be added here if needed
    }
  };
  const handleOpenPreferences = (e: MouseEvent) => {
    e.preventDefault();
    openPreferences();
  };
  return <div id="consent-banner" className={`${!isCustomizing ? 'fixed bottom-0 left-0 right-0 bg-light-backgroundPlainPure border-t border-light-strokeNormal shadow-lg z-[60] flex sm:items-center items-start' : 'fixed inset-0 bg-black/50 flex items-end sm:items-center justify-center p-4 z-[60]'}`} data-sentry-component="ConsentBanner" data-sentry-source-file="ConsentBanner.tsx">
      {!isCustomizing ? <div className="sm:mx-auto px-4 py-2 flex flex-col sm:flex-row items-start sm:items-center gap-2 sm:gap-4 text-base z-[70]">
          <p className="text-light-textPlainPrimary text-left">
            We use cookies to enhance your experience.
          </p>
          <div className="flex flex-wrap items-center gap-4 sm:gap-8 shrink-0">
            <button type="button" onClick={handleAccept} className="bg-light-textPurplePlainPrimary text-light-textPlainInverse px-6 py-3 rounded-xl font-bold transition-colors hover:brightness-95 min-w-[100px]">
              Accept All
            </button>
            <div className="flex gap-4 text-sm">
              <button type="button" onClick={handleReject} className="text-light-textPlainTertiary hover:text-light-textPlainSecondary hover:underline">
                Reject
              </button>
              <span className="text-light-textPlainTertiary hidden sm:block">•</span>
              <button type="button" onClick={handleOpenPreferences} className="text-light-textPlainTertiary hover:text-light-textPlainSecondary hover:underline">
                Customize
              </button>
            </div>
          </div>
        </div> : <div className="fixed inset-0 bg-black/50 flex items-end sm:items-center justify-center p-4">
          <div className="w-full max-w-md bg-light-backgroundPlainPure p-6 rounded-xl border border-light-strokeNormal shadow-lg">
            {isDoNotSell && <div className="text-sm text-red600 p-4 bg-red-50 rounded-lg mb-4">
                You have opted out of data sharing. These settings cannot be
                changed until you explicitly opt back in.
              </div>}
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <h3 className="text-base font-bold text-light-textPlainPrimary">
                  Cookie Preferences
                </h3>
                <button onClick={closePreferences} className="text-light-textPlainSecondary hover:text-light-textPlainPrimary p-2">
                  ✕
                </button>
              </div>

              <div className="space-y-3">
                <label className="flex items-center justify-between text-base p-2">
                  <span className="text-light-textPlainPrimary">
                    Necessary (Required)
                  </span>
                  <input type="checkbox" checked={true} disabled={true} className="h-5 w-5 rounded accent-light-graphicsPurpleButtons" />
                </label>

                <label className="flex items-center justify-between text-base p-2">
                  <span className="text-light-textPlainPrimary">Analytics</span>
                  <input type="checkbox" checked={tempConsents.analytics} onChange={e => setTempConsents(prev => ({
                ...prev,
                analytics: e.target.checked
              }))} disabled={isDoNotSell} className="h-5 w-5 rounded accent-light-graphicsPurpleButtons" />
                </label>

                <label className="flex items-center justify-between text-base p-2">
                  <span className="text-light-textPlainPrimary">Marketing</span>
                  <input type="checkbox" checked={tempConsents.marketing} onChange={e => setTempConsents(prev => ({
                ...prev,
                marketing: e.target.checked
              }))} disabled={isDoNotSell} className="h-5 w-5 rounded accent-light-graphicsPurpleButtons" />
                </label>
              </div>

              <button onClick={() => {
            updateAllConsents(tempConsents);
            closePreferences();
          }} className="w-full bg-light-textPurplePlainPrimary text-light-textPlainInverse px-6 py-3 rounded-xl text-base font-bold transition-colors hover:brightness-95 disabled:opacity-50" disabled={isDoNotSell}>
                Save Preferences
              </button>
            </div>
          </div>
        </div>}
    </div>;
}