'use client';

import { faEnvelopeOpenDollar, faCircleDollar, faGaugeCircleBolt, faRotate } from '@awesome.me/kit-e1dfe1051a/icons/duotone/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
export function EVFinder() {
  return <div className=" max-w-7xl mx-auto" data-sentry-component="EVFinder" data-sentry-source-file="EVFinder.tsx">
      <h2 className="mb-4 md:mb-8 text-2xl md:text-4xl font-bold">
        Find the right EV for you
      </h2>
      <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 md:grid-cols-4">
        <Link href="/vehicles?taxCreditEligible=true" className="flex flex-col bg-light-backgroundPlainPure items-center justify-center rounded-xl border border-light-strokeNormal p-6 transition-all duration-200 hover:shadow-lg" data-sentry-element="Link" data-sentry-source-file="EVFinder.tsx">
          <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full text-light-graphicsPurpleIcons">
            <FontAwesomeIcon icon={faEnvelopeOpenDollar} size="3x" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="EVFinder.tsx" />
          </div>
          <span className="text-center text-base font-semibold">
            Qualifies for Used EV Credit
          </span>
        </Link>

        <Link href="/vehicles?maxPrice=30000" className="flex flex-col bg-light-backgroundPlainPure items-center justify-center rounded-xl border border-light-strokeNormal p-6 transition-all duration-200 hover:shadow-lg" data-sentry-element="Link" data-sentry-source-file="EVFinder.tsx">
          <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full text-light-graphicsPurpleIcons">
            <FontAwesomeIcon icon={faCircleDollar} size="3x" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="EVFinder.tsx" />
          </div>
          <span className="text-center text-base font-semibold">
            Under $30,000
          </span>
        </Link>

        <Link href="/vehicles?minRange=250" className="flex flex-col bg-light-backgroundPlainPure items-center justify-center rounded-xl border border-light-strokeNormal p-6 transition-all duration-200 hover:shadow-lg" data-sentry-element="Link" data-sentry-source-file="EVFinder.tsx">
          <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full text-light-graphicsPurpleIcons">
            <FontAwesomeIcon icon={faGaugeCircleBolt} size="3x" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="EVFinder.tsx" />
          </div>
          <span className="text-center text-base font-semibold">
            250+ mi range
          </span>
        </Link>

        <Link href="/sell" className="flex flex-col bg-light-backgroundPlainPure items-center justify-center rounded-xl border border-light-strokeNormal p-6 transition-all duration-200 hover:shadow-lg" data-sentry-element="Link" data-sentry-source-file="EVFinder.tsx">
          <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full text-light-graphicsPurpleIcons">
            <FontAwesomeIcon icon={faRotate} size="3x" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="EVFinder.tsx" />
          </div>
          <span className="text-center text-base font-semibold">
            Sell or trade-in
          </span>
        </Link>
      </div>
    </div>;
}