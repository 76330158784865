import axios from 'axios';

import { logger } from '~/utils/logger';

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APP_URL,
  timeout: 5000,
});

// Add response interceptor for consistent error handling
api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    logger.error('API Error', {
      url: error.config?.url,
      status: error.response?.status,
      error: error.message,
    });
    return Promise.reject(error);
  },
); 