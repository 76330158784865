'use client';

import Link from 'next/link';
import { api } from '~/trpc/react';
interface ButtonProps {
  button: {
    label: string;
    count: number;
    filter: string;
    searchParams: Record<string, string>;
  };
  isFirst: boolean;
}
function QuickFilterButton({
  button,
  isFirst
}: ButtonProps) {
  const params = new URLSearchParams(button.searchParams);
  const href = `/vehicles${params.toString() ? `?${params.toString()}` : ''}`;
  return <Link href={href} className={`flex h-10 items-center whitespace-nowrap rounded-2xl border-2 px-4 py-2 ${isFirst ? 'bg-light-textPlainPrimary text-light-textPlainInverse border-none hover:bg-gray-800' : 'border-light-graphicsPurpleBackgrounds bg-light-backgroundPlainPure hover:bg-gray-50  text-black'}`} data-sentry-element="Link" data-sentry-component="QuickFilterButton" data-sentry-source-file="QuickFilters.tsx">
      <span className="font-semibold truncate">{button.label}</span>
      <span className="ml-1 font-normal shrink-0">({button.count})</span>
    </Link>;
}
export function QuickFilters() {
  const {
    data: buttons = [],
    isLoading
  } = api.filters.getCounts.useQuery(undefined, {
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 30,
    refetchOnWindowFocus: false
  });
  if (isLoading) {
    return <QuickFiltersSkeleton />;
  }
  return <div className="flex flex-wrap justify-center gap-2 md:gap-4" data-sentry-component="QuickFilters" data-sentry-source-file="QuickFilters.tsx">
      {buttons.map((button, index) => <QuickFilterButton key={button.filter} button={button} isFirst={index === 0} />)}
    </div>;
}
export function QuickFiltersSkeleton() {
  return <div className="flex flex-wrap justify-center gap-2 md:gap-4" data-sentry-component="QuickFiltersSkeleton" data-sentry-source-file="QuickFilters.tsx">
      {Array.from({
      length: 5
    }).map((_, i) => <div key={i} className={`flex h-10 w-32 items-center whitespace-nowrap rounded-2xl border-2 px-4 py-2 ${i === 0 ? 'bg-light-textPlainPrimary text-light-textPlainInverse' : 'border-light-graphicsPurpleBackgrounds bg-light-backgroundPlainPure'} animate-pulse`} />)}
    </div>;
}