'use client';

import * as Sentry from '@sentry/nextjs';
import Error from 'next/error';
import { useEffect } from 'react';
export default function GlobalError({
  error
}: {
  error: Error;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>{/* Your Error component here... */}</body>
    </html>;
}