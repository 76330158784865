'use client';

import * as Sentry from '@sentry/nextjs';
import Script from 'next/script';
import { useState } from 'react';
import { logger } from '~/utils/logger';
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

export function UpstartWidget() {
  const [retryCount, setRetryCount] = useState(0);
  const handleError = (error: Error) => {
    Sentry.captureException(error, {
      tags: {
        component: 'UpstartWidget',
        retryAttempt: retryCount
      }
    });
    logger.error('Failed to load Upstart widget', {
      error
    });

    // Retry logic
    if (retryCount < MAX_RETRIES) {
      setTimeout(() => {
        setRetryCount(prev => prev + 1);
      }, RETRY_DELAY);
    }
  };
  return <Script src="https://widgets.upstart.com/priceButtonGroupAgent.v1.0.js" id="prodigyButtonGroupAgent"
  // @ts-ignore: Suppress TypeScript error for websiteId
  websiteId="recharged" strategy="afterInteractive" onError={handleError} key={`upstart-widget-${retryCount}`} // Force script reload on retry
  data-sentry-element="Script" data-sentry-component="UpstartWidget" data-sentry-source-file="UpstartWidget.tsx" />;
}