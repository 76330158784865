'use client';

import { faMagnifyingGlass } from '@awesome.me/kit-e1dfe1051a/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/navigation';
import { type FormEvent, useState } from 'react';
export function SearchBar() {
  const [query, setQuery] = useState('');
  const router = useRouter();
  const handleSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    router.push(`/vehicles${query.trim() ? `?search=${encodeURIComponent(query.trim())}` : ''}`);
  };
  const handleClick = () => {
    router.push('/vehicles');
  };
  return <div className="flex flex-col mt-4 items-center" data-sentry-component="SearchBar" data-sentry-source-file="SearchBar.tsx">
      <div className="w-full max-w-4xl px-4 sm:px-0">
        <div className="rounded-[24px] bg-gradient-to-r from-light-graphicsPurpleIcons to-light-graphicsPurpleButtons p-[2px]">
          <form onSubmit={handleSearch} className="flex items-center rounded-[22px] bg-light-backgroundPlainPure px-4 sm:px-8 py-3 sm:py-4 h-[48px] sm:h-[56px]">
            <input type="text" placeholder="Search by make or model" value={query} onChange={e => setQuery(e.target.value)} className="!border-none text-light-textPlainTertiary text-sm sm:text-base flex-grow !outline-none text-[16px] placeholder:text-light-textPlainTertiary" />
            <button type="submit" className="ml-2" aria-label="Search vehicles" onClick={query.trim() ? undefined : handleClick}>
              <FontAwesomeIcon icon={faMagnifyingGlass} className="text-textPlainSecondary" size="1x" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="SearchBar.tsx" />
            </button>
          </form>
        </div>
      </div>
    </div>;
}
export function SearchBarSkeleton() {
  return <div className="flex flex-col items-center" data-sentry-component="SearchBarSkeleton" data-sentry-source-file="SearchBar.tsx">
      <div className="w-full max-w-4xl px-4 sm:px-0">
        <div className="rounded-[24px] bg-gradient-to-r from-light-graphicsPurpleIcons/20 to-light-graphicsPurpleButtons/20 p-[2px]">
          <div className="h-[48px] sm:h-[56px] w-full animate-pulse rounded-[22px] bg-light-backgroundPlainLight" />
        </div>
      </div>
    </div>;
}