'use client';

import { faBatteryBolt, faClockRotateLeft, faChartMixedUpCircleDollar } from '@awesome.me/kit-e1dfe1051a/icons/duotone/solid';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ScoreSummary } from '~/app/_components/Score';
import { colorAssignments } from '~/styles/colors';
const FeatureCard = ({
  icon: IconComponent,
  title,
  description
}: {
  icon: any;
  title: string;
  description: string;
}) => <div className="relative" data-sentry-component="FeatureCard" data-sentry-source-file="ScoreReport.tsx">
    <div className="absolute inset-0" style={{
    backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(icon(IconComponent, {
      styles: {
        color: colorAssignments.light.graphicsGreenIcons
      }
    }).html[0] || '')}")`,
    backgroundPosition: '13rem 6.25rem',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '10rem auto',
    opacity: 0.1
  }} />
    <div className="relative p-8">
      <FontAwesomeIcon icon={IconComponent} className="text-light-graphicsGreenIcons" size="2x" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="ScoreReport.tsx" />
      <h3 className="text-lg mb-2 text-light-textPurplePlainPrimary ">
        {title}
      </h3>
      <p className="text-sm text-textPlainSecondary">{description}</p>
    </div>
  </div>;
export const ScoreReport = () => <div className="relative w-full max-w-7xl mx-auto" data-sentry-component="ScoreReport" data-sentry-source-file="ScoreReport.tsx">
    <div className="text-center mb-8 md:mb-16">
      <div className="inline-block relative rounded-2xl p-6 shadow-[0px_0.625px_1.875px_0px_rgba(0,0,0,0.20),_0px_1.25px_10px_0px_rgba(0,0,0,0.05)]" style={{
      background: 'radial-gradient(96.71% 59.23% at 30.94% 50.51%, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.50) 100%), var(--Background-Green-Light, #F5FEFB)',
      backgroundBlendMode: 'lighten, normal'
    }}>
        <div className="absolute inset-0 z-0" style={{
        backgroundImage: 'url(/bolt.svg)',
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '170px auto'
      }} />

        <div className="relative z-10">
          <ScoreSummary score={4.89} maxScore={5} data-sentry-element="ScoreSummary" data-sentry-source-file="ScoreReport.tsx" />
        </div>
      </div>

      <div className="flex justify-center">
        <h2 className="text-2xl md:text-4xl font-bold my-4 md:my-8">
          Get the full picture with a Recharged Score Report
        </h2>
      </div>

      <p className="text-textPlainSecondary text-xl max-w-3xl mx-auto">
        Every EV we sell has gone through a comprehensive check so you can buy
        with confidence. Here's what's our Recharged Score is made up of:
      </p>
    </div>

    <div className="grid grid-cols-1 sm:grid-cols-3 divide-y sm:divide-y-0 sm:divide-x divide-strokeSoft rounded-2xl bg-[#F5FEFB] border border-strokeSoft">
      <FeatureCard icon={faBatteryBolt} title="Battery health" description="Comprehensive battery health assessment to provide clear insights into range and reliability." data-sentry-element="FeatureCard" data-sentry-source-file="ScoreReport.tsx" />
      <FeatureCard icon={faClockRotateLeft} title="Vehicle history" description="Transparent summary of ownership, accidents, and maintenance history." data-sentry-element="FeatureCard" data-sentry-source-file="ScoreReport.tsx" />
      <FeatureCard icon={faChartMixedUpCircleDollar} title="Market pricing" description="Clear pricing guidance based on real market trends for a fair and informed purchase." data-sentry-element="FeatureCard" data-sentry-source-file="ScoreReport.tsx" />
    </div>
  </div>;