'use client';

import { useConsent } from '../providers/consent/ConsentContext';
export function CookiePreferencesButton() {
  const {
    openPreferences
  } = useConsent();
  return <button onClick={e => {
    e.preventDefault();
    openPreferences();
  }} className="inline hover:text-light-textPlainSecondary" data-sentry-component="CookiePreferencesButton" data-sentry-source-file="CookiePreferencesButton.tsx">
      Manage Cookie Policy
    </button>;
}